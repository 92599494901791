import dynamic from 'next/dynamic';

const OrdersGrid = dynamic(() => import('./OrdersGrid/OrdersGrid'));
const OrderFilters = dynamic(() => import('./OrderFilters/OrderFilters'));

const MarkAsPaidModal = dynamic(() =>
  import('./MarkAsPaidModal/MarkAsPaidModal')
);

const RefundTransaction = dynamic(() =>
  import('./RefundTransaction/RefundTransaction')
);

const OrderUserDetails = dynamic(() =>
  import('./OrderUserDetails/OrderUserDetails')
);
const OrderForm = dynamic(() => import('./OrderForm/OrderForm'));
const OrderProducts = dynamic(() => import('./OrderProducts/OrderProducts'));
const OrderTransactionStatus = dynamic(() =>
  import('./OrderTransactionStatus/OrderTransactionStatus')
);
const OrderTransactions = dynamic(() =>
  import('./OrderTransactions/OrderTransactions')
);

export {
  OrderUserDetails,
  OrderForm,
  OrdersGrid,
  OrderFilters,
  MarkAsPaidModal,
  RefundTransaction,
  OrderProducts,
  OrderTransactions,
  OrderTransactionStatus,
};
