import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
} from '@services';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { AGENT_ROLES } from '@constants';
import {
  handleAssigneeData,
  handleTrafficOffensesTaskData,
  handleVehicleData,
} from './utils';

export const useTrafficOffensesTaskOperations = () => {
  const router = useRouter();
  const trafficOffensesTaskId = router.query?.trafficOffensesTaskId;
  const customerSuccessCoordinatorAssignees = [];
  const customerSuccessTeamLeadAssignees = [];

  const agentsResponse = useQuery([QUERY_KEYS.GET_AGENTS_KEY], () =>
    agentsServices.getAgents({
      page: 1,
      pageSize: 3000,
    })
  );

  agentsResponse?.data?.data?.forEach((agent) => {
    if (agent?.role === AGENT_ROLES.isCustomerSuccessCoordinator) {
      customerSuccessCoordinatorAssignees.push(agent);
    } else if (agent?.role === AGENT_ROLES.isCustomerSuccessTeamLead) {
      customerSuccessTeamLeadAssignees.push(agent);
    }
  });

  const agents =
    agentsResponse?.data?.data
      ?.filter((user) => user?.isActive === true)
      ?.map((item) => {
        return {
          id: item?.email,
          display: `${item?.firstName || ''} ${item?.lastName || ''}`,
        };
      }) || [];

  const assignees = handleAssigneeData([
    ...(customerSuccessCoordinatorAssignees || []),
    ...(customerSuccessTeamLeadAssignees || []),
  ]);

  const taskResponse = useQuery(
    [QUERY_KEYS.GET_TRAFFIC_OFFENSES_TASK_KEY, trafficOffensesTaskId],
    () => boardsServices.getTaskById(trafficOffensesTaskId),
    {
      enabled: agentsResponse.isFetched,
    }
  );

  const task = handleTrafficOffensesTaskData(
    taskResponse?.data?.data,
    assignees
  );
  const carId = taskResponse?.data?.data?.customFields?.carId;

  const vehicleResponse = useQuery(
    [QUERY_KEYS.GET_VEHICLE_BY_INTERNAL_ID_KEY, trafficOffensesTaskId],
    () => fleetVehiclesServices.getVehicleByInternalId(carId),
    {
      enabled: taskResponse.isFetched && !!carId,
    }
  );

  const vehicle = handleVehicleData(vehicleResponse?.data?.data);

  const loading =
    agentsResponse.isFetching ||
    taskResponse.isFetching ||
    vehicleResponse.isFetching;

  const updateTask = async (payload = {}) => {
    try {
      if (payload?.cardId) {
        const taskId = payload?.cardId;
        delete payload?.cardId;
        await boardsServices.updateTask(taskId, payload);
      } else {
        await boardsServices.updateTask(payload?.id, payload);
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (agentsResponse?.isError) {
      showError(agentsResponse?.error);
    }
  }, [agentsResponse?.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (taskResponse?.isError) {
      showError(taskResponse?.error);
    }
  }, [taskResponse?.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleResponse?.isError) {
      showError(vehicleResponse?.error);
    }
  }, [vehicleResponse?.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    task,
    taskId: task?.id,
    getTrafficOffensesTask: taskResponse?.refetch,
    agents,
    updateTask,
    vehicle,
    assignees,
  };
};

export default useTrafficOffensesTaskOperations;
