/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { agentsServices, reservationServices } from '@services';
import {
  normalizeAssigneeData,
  normalizeReservationData,
  normalizeReservationHistory,
  normalizeReservationsEnums,
} from './utils';

export const useReservationOperations = () => {
  const router = useRouter();
  const reservationId = router.query?.reservationId;
  const { back } = router;

  const reservationResponse = useQuery(
    [QUERY_KEYS.GET_RESERVATION_KEY, reservationId],
    () => reservationServices.getReservationById(reservationId),
    {
      enabled: !!reservationId,
    }
  );

  const reservationsEnumsResponse = useQuery(
    QUERY_KEYS.GET_RESERVATIONS_ENUMS_KEY,
    () => reservationServices.getReservationsEnums()
  );

  const reservationsTypes = normalizeReservationsEnums(
    reservationsEnumsResponse?.data?.data || []
  );

  const reservation = normalizeReservationData(
    reservationResponse?.data?.data || []
  );

  const reservationHistoryResponse = useQuery(
    [QUERY_KEYS.GET_RESERVATION_HISTORY_KEY, reservationId],
    () => reservationServices.getReservationsHistoryById(reservationId),
    {
      enabled: !!reservationId,
    }
  );

  const history = normalizeReservationHistory(
    reservationHistoryResponse?.data?.data || []
  );

  const assigneesResponse = useQuery(
    QUERY_KEYS.GET_RESERVATION_ASSIGNEES_KEY,
    () =>
      agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      })
  );

  const assignees = normalizeAssigneeData(assigneesResponse?.data?.data || []);

  const mentionedUsers = assignees
    ?.filter((user) => user?.isActive === true)
    ?.map?.((user) => {
      return { id: user?.value, display: user?.text };
    });

  const loading =
    reservationResponse.isFetching ||
    reservationHistoryResponse.isFetching ||
    assigneesResponse.isFetching;

  useEffect(() => {
    if (reservationResponse.isError) {
      showError(reservationResponse.error);
    }
  }, [reservationResponse.isError]);

  useEffect(() => {
    if (reservationHistoryResponse.isError) {
      showError(reservationHistoryResponse.error);
    }
  }, [reservationHistoryResponse.isError]);

  useEffect(() => {
    if (assigneesResponse.isError) {
      showError(assigneesResponse.error);
    }
  }, [assigneesResponse.isError]);

  useEffect(() => {
    if (reservationsEnumsResponse.isError) {
      showError(reservationsEnumsResponse.error);
    }
  }, [reservationResponse.isError]);

  return {
    loading,
    reservationId,
    reservation,
    getReservationHasErrors: reservationResponse.isError,
    getReservationErrorMessage: reservationResponse.error,
    getReservation: reservationResponse.refetch,
    goBack: back,
    history,
    getHistory: reservationHistoryResponse.refetch,
    agents: assignees,
    reservationsTypes,
    mentionedUsers,
  };
};
