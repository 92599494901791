import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { boardsServices, agentsServices } from '@services';
import { showError } from '@utils';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import { ARNM_MAINTENANCE_TASK_STATUSES_NAMES } from 'components/arnmMaintenance/constants';
import { groupBy } from 'lodash';
import {
  handleArnmMaintenanceData,
  handleAssigneeData,
} from './arnmMaintenance.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  arnmMaintenance: [],
  assignees: [],
  total: 0,
  pageSize: 1000,
};
// Actual Slice
export const arnmMaintenanceSlice = createSlice({
  name: 'arnmMaintenance',
  initialState,
  reducers: {
    fetchArnmMaintenance: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.returnTask = {};
      state.subTasks = [];
    },
    fetchArnmMaintenanceSuccess: (state, { payload }) => {
      state.arnmMaintenance = handleArnmMaintenanceData(
        payload?.response?.data,
        payload?.assignees?.data
      );
      state.assignees = handleAssigneeData(payload?.assignees?.data);
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchArnmMaintenanceFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    fetchArnmMaintenanceAssignees: (state) => {
      state.assignees.loading = true;
      state.assignees.hasErrors = false;
      state.assignees.errorMessage = '';
    },
    fetchArnmMaintenanceAssigneesSuccess: (state, { payload }) => {
      state.assignees.loading = false;
      state.assignees.data = handleAssigneeData(payload.assignees);
    },
    fetchArnmMaintenanceAssigneesFailure: (state, { payload }) => {
      state.assignees.loading = false;
      state.assignees.data = [];
      state.assignees.hasErrors = true;
      state.assignees.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchArnmMaintenance,
  fetchArnmMaintenanceSuccess,
  fetchArnmMaintenanceFailure,
  fetchArnmMaintenanceAssignees,
  fetchArnmMaintenanceAssigneesSuccess,
  fetchArnmMaintenanceAssigneesFailure,
} = arnmMaintenanceSlice.actions;

export const selectArnmMaintenanceState = (state) => state.arnmMaintenance;
export const selectArnmMaintenanceColumnsState = ({ arnmMaintenance }) => {
  const { tasks } = arnmMaintenance?.arnmMaintenance || {};
  const order = [0, 2, 13, 14, 15, 3, 12];
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: ARNM_MAINTENANCE_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: ARNM_MAINTENANCE_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: ARNM_MAINTENANCE_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const getArnmMaintenance = (payload) => {
  return async (dispatch) => {
    dispatch(fetchArnmMaintenance());
    const page = parseInt(payload?.page, 10);

    try {
      const response = await boardsServices.getBoardsFilters(payload);
      response.meta.page = page;
      const assignees = await agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
      dispatch(fetchArnmMaintenanceSuccess({ response, assignees }));
    } catch (error) {
      dispatch(fetchArnmMaintenanceFailure(error));
    }
  };
};

export const getArnmMaintenanceAssignees = (payload = {}) => {
  return async (dispatch) => {
    dispatch(fetchArnmMaintenanceAssignees());

    try {
      const assignees = await agentsServices.getAgents(payload);
      dispatch(
        fetchArnmMaintenanceAssigneesSuccess({
          assignees: assignees?.data || [],
        })
      );
    } catch (error) {
      showError(error);
      dispatch(fetchArnmMaintenanceAssigneesFailure(error));
    }
  };
};
export default arnmMaintenanceSlice.reducer;
