/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import { getUrl, showError } from '@utils';
import {
  agentsServices,
  boardsServices,
  servicePointsServices,
} from '@services';
import { AGENT_ROLES, BOARDS, BOARDS_ID } from '@constants';
import { QUERY_KEYS } from 'constants/queryKeys';
import {
  SERVICE_POINTS_PAGE_SIZE,
  TRAFFIC_OFFENSES_PAGE_SIZE,
} from './constants';
import {
  getColumns,
  getTrafficOffensesPayload,
  getTrafficOffensesQueryParams,
  normaliseAssigneesData,
  normaliseTrafficOffensesTasks,
  normalizeServicePointsTaskData,
} from './utils';

export const useTrafficOffensesOperations = () => {
  const router = useRouter();

  const page = router.query?.page || 1;
  const orderBy = router.query?.orderBy || 'createdAt';
  const order = router.query?.order || 'desc';

  const params = {
    page,
    orderBy,
    order,
  };

  const cleanParams = getTrafficOffensesQueryParams(params);

  const customerSuccessCoordinatorAssigneesResponse = useQuery(
    [
      QUERY_KEYS.GET_TRAFFIC_OFFENSES_ASSIGNEES_KEY,
      AGENT_ROLES.isCustomerSuccessCoordinator,
    ],
    () =>
      agentsServices.getAgents({
        page: 1,
        pageSize: 300,
        role: AGENT_ROLES.isCustomerSuccessCoordinator,
      })
  );

  const customerSuccessTeamLeadAssignees = useQuery(
    [
      QUERY_KEYS.GET_TRAFFIC_OFFENSES_ASSIGNEES_KEY,
      AGENT_ROLES.isCustomerSuccessTeamLead,
    ],
    () =>
      agentsServices.getAgents({
        page: 1,
        pageSize: 300,
        role: AGENT_ROLES.isCustomerSuccessTeamLead,
      })
  );

  const servicePointsResponse = useQuery(
    QUERY_KEYS.GET_TRAFFIC_OFFENSES_SERVICE_POINTS_KEY,
    () =>
      servicePointsServices.getServicePoints({
        page: 1,
        pageSize: SERVICE_POINTS_PAGE_SIZE,
      })
  );

  const trafficOffensesResponse = useQuery(
    [QUERY_KEYS.GET_TRAFFIC_OFFENSES_KEY, cleanParams],
    () => {
      const payload = getTrafficOffensesPayload({
        orderBy,
        order,
        page,
        pageSize: TRAFFIC_OFFENSES_PAGE_SIZE,
        boardId: BOARDS_ID.TRAFFIC_OFFENSES,
        boardType: BOARDS.TRAFFIC_OFFENSES,
      });

      return boardsServices.getBoardsFilters(payload);
    },
    {
      enabled:
        customerSuccessCoordinatorAssigneesResponse.isFetched &&
        customerSuccessTeamLeadAssignees.isFetched,
    }
  );

  const handlePageChange = (_event, newPage) => {
    router.push(getUrl({ ...router.query, page: newPage }));
  };

  const handleSortBy = (sortByModel) => {
    const url = getUrl({ ...router.query, ...sortByModel });

    router.push(url);
  };

  const loading =
    trafficOffensesResponse?.isFetching ||
    customerSuccessCoordinatorAssigneesResponse?.isFetching ||
    customerSuccessTeamLeadAssignees?.isFetching ||
    servicePointsResponse?.isFetching;

  const assignees = normaliseAssigneesData([
    ...(customerSuccessCoordinatorAssigneesResponse?.data?.data || []),
    ...(customerSuccessTeamLeadAssignees?.data?.data || []),
  ]);

  const servicePoints = normalizeServicePointsTaskData(
    servicePointsResponse?.data?.data || []
  );

  const trafficOffensesTasks = normaliseTrafficOffensesTasks(
    trafficOffensesResponse?.data?.data || [],
    assignees || []
  );

  useEffect(() => {
    if (trafficOffensesResponse.isError) {
      showError(trafficOffensesResponse?.error);
    }
  }, [trafficOffensesResponse.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    page,
    pageSize: TRAFFIC_OFFENSES_PAGE_SIZE,
    total: trafficOffensesResponse?.data?.meta?.total,
    loading,
    trafficOffenses: trafficOffensesTasks?.tasks,
    assignees,
    servicePoints,
    columns: getColumns(trafficOffensesTasks?.tasks || []),
    getTrafficOffenses: trafficOffensesResponse.refetch,
    handlePageChange,
    handleSortBy,
  };
};

export default useTrafficOffensesOperations;
