import { request } from '@utils';
import { FILTER_RULE_ENUMS, LIMIT } from '@constants';
import { isEmpty, omit } from 'lodash';

const getServicePoints = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  const queryParams = `?page=${payload?.page}&pageSize=${payload?.pageSize}`;

  let body = { ...omit(payload, ['pageSize', 'page']) };
  if (isEmpty(body)) {
    body = {
      type: FILTER_RULE_ENUMS.EmptyRuleType,
    };
  }

  const results = await request.post(`/v1/servicePoints/filter${queryParams}`, {
    body,
  });

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getServicePointsFilters = async (payload) => {
  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload.order) {
    payload.order = 'asc';
    payload.orderBy = 'id';
  }

  const queryParams = `?page=${payload?.page}&pageSize=${payload?.pageSize}&order=${payload?.order}&orderBy=${payload?.orderBy}`;
  delete payload?.pageSize;
  delete payload?.page;
  delete payload?.order;
  delete payload?.orderBy;
  const body = {
    body: payload,
  };
  const results = await request.post(
    `/v1/servicePoints/filter${queryParams}`,
    body
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getServicePointsEnums = async () => {
  const results = await request.get(`/v1/servicePoints/enums`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getServicePoint = async (id) => {
  const results = await request.get(`/v1/servicePoints/${id}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const deleteServicePoint = async (servicePointId) => {
  const results = await request.remove(`/v1/servicePoints/${servicePointId}`);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addServicePoint = async (payload) => {
  const body = {
    body: payload,
  };
  const results = await request.post(`/v1/servicePoints`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const getServicePointAddresses = async (servicePointId) => {
  const results = await request.get(
    `/v1/servicePoints/${servicePointId}/addresses`
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addServicePointAddress = async (servicePointId, address) => {
  const payload = {
    body: address,
  };
  const results = await request.post(
    `/v1/servicePoints/${servicePointId}/addresses`,
    payload
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editServicePointField = async (servicePointId, fieldName, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/servicePoints/${servicePointId}/${fieldName}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editServicePoint = async (servicePointId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/servicePoints/${servicePointId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const addServicePointContact = async (servicePointId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.post(
    `/v1/servicePoints/${servicePointId}/contact`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const editServicePointContact = async (servicePointId, contactId, payload) => {
  const params = {
    body: payload,
  };
  const results = await request.patch(
    `/v1/servicePoints/${servicePointId}/contact/${contactId}`,
    params
  );

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

const activateDeactivateServicePoints = async (payload) => {
  const body = {
    body: { servicePointIDs: payload.servicePointsIds, status: payload.action },
  };
  const results = await request.patch(`/v1/servicePoints/status`, body);

  if (results?.error) {
    return Promise.reject(results?.message);
  }

  return results;
};

export {
  getServicePoints,
  deleteServicePoint,
  addServicePoint,
  getServicePoint,
  getServicePointAddresses,
  addServicePointAddress,
  editServicePointField,
  getServicePointsEnums,
  editServicePoint,
  addServicePointContact,
  editServicePointContact,
  getServicePointsFilters,
  activateDeactivateServicePoints,
};
