import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  sellVehiclesServices,
  servicePointsServices,
  vehiclesServices,
} from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { asyncForEach } from '@utils';
import {
  handleSellVehicles,
  handleSellVehicleData,
  handleLeaseVehicleData,
} from './sellVehicle.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  sellVehicles: [],
  vehicle: {},
  total: 0,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const sellVehiclesSlice = createSlice({
  name: 'sellVehicles',
  initialState,
  reducers: {
    fetchSellVehicles: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchSellVehiclesSuccess: (state, { payload }) => {
      state.sellVehicles = handleSellVehicles(payload?.data);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchSellVehiclesFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchSellVehicle: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.vehicle = initialState.vehicle;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postSuccess = false;
      state.postErrorMessage = '';
    },
    fetchSellVehicleSuccess: (state, { payload }) => {
      state.vehicle = handleSellVehicleData(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchSellVehicleFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchLeaseVehicleSuccess: (state, { payload }) => {
      state.vehicle = handleLeaseVehicleData(payload);
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchLeaseVehicleFailure: (state) => {
      state.loading = false;
      // state.hasErrors = true;
      // state.errorMessage = GENERIC_ERROR_MESSAGE;
    },
    resetSellVehicles: (state) => {
      state.sellVehicles = [];
    },
    clearSellVehicle: (state) => {
      state.vehicle = initialState.vehicle;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = false;
    },
    postSellVehicle: (state) => {
      state.postLoading = true;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = false;
    },
    postSellVehicleSuccess: (state) => {
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
      state.postSuccess = true;
    },
    postSellVehicleFailure: (state, { payload }) => {
      state.postLoading = false;
      state.postHasErrors = true;
      state.postErrorMessage = payload;
    },
    deleteSellVehicleImageSuccess: (state, { payload }) => {
      const vehicle = { ...state?.vehicle };

      if (vehicle?.vehicle?.images?.length) {
        const images = vehicle?.vehicle?.images?.filter((image) => {
          return image?.id !== payload;
        });
        vehicle.vehicle.images = images;
      }
      state.vehicle = vehicle;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
    },

    deleteSellVehicleFileSuccess: (state, { payload }) => {
      const vehicle = { ...state?.vehicle };

      if (vehicle?.vehicle?.docs?.length) {
        const docs = vehicle?.vehicle?.docs?.filter((doc) => {
          return doc?.id !== payload;
        });
        vehicle.vehicle.docs = docs;
      }
      state.vehicle = vehicle;
      state.postLoading = false;
      state.postHasErrors = false;
      state.postErrorMessage = '';
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchSellVehicles,
  fetchSellVehiclesSuccess,
  fetchSellVehiclesFailure,
  fetchSellVehicle,
  fetchSellVehicleSuccess,
  fetchSellVehicleFailure,
  fetchLeaseVehicleSuccess,
  fetchLeaseVehicleFailure,
  postSellVehicle,
  postSellVehicleSuccess,
  postSellVehicleFailure,
  resetSellVehicles,
  clearSellVehicle,
  deleteSellVehicleImageSuccess,
  deleteSellVehicleFileSuccess,
} = sellVehiclesSlice.actions;

export const selectSellVehiclesState = (state) => state.sellVehicles;

export const getSellVehicles = (payload) => {
  return async (dispatch) => {
    dispatch(fetchSellVehicles());
    const page = parseInt(payload?.page, 10);
    try {
      const response = await sellVehiclesServices?.getSellVehicles(payload);
      response.meta.page = page;
      dispatch(fetchSellVehiclesSuccess(response));
    } catch (error) {
      dispatch(fetchSellVehiclesFailure(error));
    }
  };
};

export const getSellVehicle = (vehicleId) => {
  return async (dispatch) => {
    dispatch(fetchSellVehicle());
    try {
      const data = {};
      if (vehicleId) {
        data.vehicle = await sellVehiclesServices?.getSellVehicle(vehicleId);
      } else {
        data.vehicle = initialState.vehicle;
      }

      data.enums = await sellVehiclesServices?.getSellVehicleEnums();
      data.colors = await sellVehiclesServices?.getSellVehicleColors();
      data.providers = await sellVehiclesServices?.getSellVehicleProviders();
      data.models = await sellVehiclesServices?.getSellVehiclesModels();
      data.dealers = await sellVehiclesServices?.getSellVehicleDealers();
      data.servicePoints = await servicePointsServices?.getServicePoints({
        page: 1,
        pageSize: 1000,
      });

      dispatch(fetchSellVehicleSuccess(data));
    } catch (error) {
      dispatch(fetchSellVehicleFailure(error));
    }
  };
};

export const addSellVehicle = (payload) => {
  return async (dispatch) => {
    dispatch(postSellVehicle());

    try {
      const { skuId } = payload.form;

      await sellVehiclesServices.postSellVehicle(payload.form);

      if (payload.docs?.length) {
        await asyncForEach(payload?.docs, async (doc) => {
          sellVehiclesServices.postSellVehicleFile(skuId, doc.file);
        });
      }

      dispatch(postSellVehicleSuccess());
    } catch (error) {
      dispatch(postSellVehicleFailure(error));
    }
  };
};

export const editSellVehicle = (payload) => {
  return async (dispatch) => {
    dispatch(postSellVehicle());

    try {
      const { id } = payload.form;
      const { primaryImageId } = payload;
      delete payload.form.id;
      const data = {
        ...payload.form,
        images: [...payload.images],
        mainImage: primaryImageId,
        // documents: [...payload.docs],
      };
      await sellVehiclesServices.updateSellVehicle(id, data);

      dispatch(postSellVehicleSuccess());
    } catch (error) {
      dispatch(postSellVehicleFailure(error));
    }
  };
};

export const deleteSellVehicleImage = (payload) => {
  return async (dispatch) => {
    dispatch(postSellVehicle());

    try {
      await sellVehiclesServices.deleteSellVehicleImage(payload);

      dispatch(deleteSellVehicleImageSuccess(payload?.id));
    } catch (error) {
      dispatch(postSellVehicleFailure(error));
    }
  };
};

export const deleteSellVehicleFile = (payload) => {
  return async (dispatch) => {
    dispatch(postSellVehicle());

    try {
      await sellVehiclesServices.deleteSellVehicleFile(payload);

      dispatch(deleteSellVehicleFileSuccess(payload?.id));
    } catch (error) {
      dispatch(postSellVehicleFailure(error));
    }
  };
};

export const resetSellVehicle = () => {
  return async (dispatch) => {
    dispatch(clearSellVehicle());
  };
};

export const getLeaseVehicle = (vehicleId) => {
  return async (dispatch) => {
    dispatch(fetchSellVehicle());
    dispatch(clearSellVehicle());
    try {
      const data = {};
      if (vehicleId) {
        data.vehicle = await vehiclesServices?.getVehicle(vehicleId);
      } else {
        data.vehicle = initialState.vehicle;
      }

      data.enums = await sellVehiclesServices?.getSellVehicleEnums();
      data.colors = await sellVehiclesServices?.getSellVehicleColors();
      data.providers = await sellVehiclesServices?.getSellVehicleProviders();
      data.models = await sellVehiclesServices?.getSellVehiclesModels();
      data.dealers = await sellVehiclesServices?.getSellVehicleDealers();

      dispatch(fetchLeaseVehicleSuccess(data));
    } catch (error) {
      dispatch(fetchLeaseVehicleFailure(error));
      return 'error';
    }
  };
};

export default sellVehiclesSlice.reducer;
