/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArnmRepairsState,
  getArnmRepairs,
  selectArnmRepairsColumnsState,
} from '@store';
import { showError } from '@utils';
import { BOARDS, BOARDS_ID } from '@constants';

export const useArnmRepairsOperations = (fetchOnMount) => {
  const {
    total,
    firstItem,
    lastItem,
    loading,
    arnmRepairs,
    pageCount,
    pageSize,
    hasErrors,
    errorMessage,
    assignees,
  } = useSelector(selectArnmRepairsState);
  const columns = useSelector(selectArnmRepairsColumnsState);
  const {
    isReady,
    push,
    query: { page = 1, orderBy = 'createdAt', order = 'desc', token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getArnmRepairs = useCallback(async () => {
    let payload = localStorage.arnmRepairsQuery;
    let { arnmRepairsFilterRules } = localStorage;
    const defaultRule = {
      type: 5,
      field: 'boardId',
      operator: 0,
      value: BOARDS_ID.ARM_REPAIRS,
    };

    if (arnmRepairsFilterRules) {
      arnmRepairsFilterRules = JSON.parse(arnmRepairsFilterRules);
    } else {
      arnmRepairsFilterRules = [];
    }
    arnmRepairsFilterRules = [defaultRule, ...arnmRepairsFilterRules];
    if (payload) {
      payload = JSON.parse(payload);
      if (payload.value) {
        payload.value = payload.value.map((item) => {
          if (item.field === 'customFields.vehiclePlate') {
            return { ...item, value: item.value.toUpperCase() };
          }
          return item;
        });
        payload.value = [...payload.value, ...arnmRepairsFilterRules];
      } else if (arnmRepairsFilterRules?.length) {
        payload = {
          type: 0,
          field: null,
          operator: null,
          value: arnmRepairsFilterRules,
        };
      }
    } else if (!payload && arnmRepairsFilterRules?.length) {
      payload = {
        type: 0,
        field: null,
        operator: null,
        value: arnmRepairsFilterRules,
      };
    } else {
      payload = defaultRule;
    }
    await dispatch(
      getArnmRepairs({
        orderBy,
        order,
        page,
        pageSize,
        boardId: BOARDS_ID.ARM_REPAIRS,
        boardType: BOARDS.ARM_REPAIRS,
        ...payload,
      })
    );
  }, [page, orderBy, order, dispatch]);

  const _sortBy = useCallback(
    (sortingModel) => {
      let url = `/arnmRepairs?orderBy=${sortingModel?.orderBy || ''}&order=${
        sortingModel?.order || ''
      }&page=${page}`;
      if (!sortingModel?.orderBy || !sortingModel?.order) {
        url = `/arnmRepairs?page=${page}`;
      }
      push(url, undefined, { shallow: true });
    },
    [push, page]
  );

  const _nextPage = useCallback(
    (currentPage) => {
      let url = `/arnmRepairs?orderBy=${orderBy || ''}&order=${
        order || ''
      }&page=${currentPage}`;
      if (!orderBy || !order) {
        url = `/arnmRepairs?page=${currentPage}`;
      }
      if (currentPage === 1) {
        return push(url);
      }
      push(url, undefined, { shallow: true });
    },
    [orderBy, order, push]
  );

  useEffect(() => {
    if (fetchOnMount && isReady) {
      _getArnmRepairs();
    }
  }, [page, token, fetchOnMount, isReady]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    page,
    total,
    firstItem,
    lastItem,
    pageSize,
    loading,
    arnmRepairs,
    pageCount,
    hasErrors,
    errorMessage,
    assignees,
    columns,
    getArnmRepairs: _getArnmRepairs,
    nextPage: _nextPage,
    sortBy: _sortBy,
  };
};
