import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { insurancesServices } from '@services';
import { handleInsuranceData } from './utils';

export const useInsuranceOperations = () => {
  const router = useRouter();
  const insuranceId = router.query?.insuranceId;

  const insuranceResponse = useQuery(
    [QUERY_KEYS.GET_INSURANCE_KEY, insuranceId],
    () => insurancesServices.getInsurance(insuranceId),
    {
      enabled: !!insuranceId,
    }
  );

  const insurance = handleInsuranceData(insuranceResponse?.data?.data || {});

  useEffect(() => {
    if (insuranceResponse?.isError) {
      showError(insuranceResponse.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceResponse?.isError]);

  return {
    loading: insuranceResponse?.isLoading,
    insurance,
    hasErrors: insuranceResponse?.isError,
    errorMessage: insuranceResponse?.error,
    getInsurance: insuranceResponse?.refetch,
  };
};
