import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getSellRequestsTask, selectSellRequestsState } from '@store';
import { showError } from '@utils';
import { boardsServices } from '@services';

export const useSellRequestsTaskOperations = () => {
  const {
    sellRequestsTask,
    vehicle,
    pageSize,
    loading,
    hasErrors,
    errorMessage,
    assignees,
  } = useSelector(selectSellRequestsState);
  const {
    query: { sellRequestsTaskId, token },
  } = useRouter();
  const dispatch = useDispatch();

  const _getSellRequestsTask = useCallback(async () => {
    await dispatch(getSellRequestsTask(sellRequestsTaskId));
  }, [sellRequestsTaskId, dispatch]);

  const mentionedUsers = assignees
    ?.filter((user) => user?.isActive === true)
    ?.map?.((user) => {
      return { id: user?.email, display: user?.text };
    });

  const updateTask = async (payload = {}) => {
    try {
      if (!payload?.id) return;

      const taskId = payload?.id;
      await boardsServices.updateTask(taskId, payload);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (sellRequestsTaskId !== undefined) {
      _getSellRequestsTask();
    }
  }, [sellRequestsTaskId, _getSellRequestsTask, token]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage]);

  return {
    loading,
    vehicle,
    task: sellRequestsTask,
    taskId: sellRequestsTaskId,
    mentionedUsers,
    assignees,
    hasErrors,
    errorMessage,
    token,
    pageSize,
    getSellRequestsTask: _getSellRequestsTask,
    updateTask,
  };
};
