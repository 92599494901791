import { formatDate, formatAmount } from '@utils';

export const handleSubscriptions = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, innerArray) => {
    const data = {
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item.updatedAt
        ? formatDate(item.updatedAt, 'DD/MM/YYYY')
        : '-',
      promisedDeliveryDate: item.promisedDeliveryDate
        ? formatDate(item.promisedDeliveryDate, 'DD/MM/YYYY')
        : '-',
      deliveryDate: item.deliveryDate
        ? formatDate(item.deliveryDate, 'DD/MM/YYYY')
        : '-',
      promisedReturnDate: item.promisedReturnDate
        ? formatDate(item.promisedReturnDate, 'DD/MM/YYYY')
        : '-',
      returnDate: item.returnDate
        ? formatDate(item.returnDate, 'DD/MM/YYYY')
        : '-',
      vehicle: item?.vehicle
        ? `${item?.vehicle?.manufacturer || ''} - ${item?.vehicle?.model || ''}`
        : '-',
      vehiclePlate: item?.vehiclePlate || '-',
      amount: item?.rules?.[0]?.amount?.value
        ? formatAmount(item?.rules?.[0]?.amount?.value)
        : '-',
      pipedriveId: item?.pipedriveId || '-',
      pipedriveUrl: item?.pipedriveUrl,
      id: item.id,
      internal_id: item.internal_id,
      type: item.type,
      status: item.status,
      // as owner we define the subscriber who has subscription with role === 1 (owner)
      owner:
        item?.subscribers?.find((subscriber) =>
          subscriber?.subscriptions?.find(
            (subscription) =>
              subscription.role === 1 && subscription.id === item.id
          )
        )?.email || '-',
      carId: item?.vehicle?.id,
      vehicleInternalId: item?.vehicleInternalId,
    };
    innerArray[index] = data;

    return innerArray;
  });
  return array;
};
