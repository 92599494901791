import { ORDERS_STATUS } from '@constants';
import { fixedNumber, formatAmount, formatDate } from '@utils';
import { head } from 'lodash';

export const handleOrders = (array) => {
  if (!array) {
    return [];
  }
  const data = array?.map((item) => {
    return {
      id: item.id,
      userId: item.userId,
      status: ORDERS_STATUS?.[item?.status] || '-',
      totalOfferAmount: formatAmount(fixedNumber(item?.totalPrice, 2)),
      receipt: item?.invoice || '-',
      createdAt: item.createdAt
        ? formatDate(item.createdAt, 'DD/MM/YYYY')
        : '-',
    };
  });
  return data;
};

const handleDetails = (details) => {
  if (!details) {
    return '-';
  }
  let detailsString = '';

  if (details?.location) {
    detailsString = details?.location;
  }
  if (details?.monthsDuration) {
    detailsString = `${details?.location} ${details?.monthsDuration} Month${
      details?.monthsDuration === 1 ? '' : 's'
    }`;
  }

  return detailsString || '-';
};

export const handleOrder = (order) => {
  return {
    ...order,
    billingInfo: {
      ...order.billingInfo,
      shortName: `${head(order.billingInfo?.firstName)}${head(
        order.billingInfo?.lastName
      )}`,
      fullName: `${order.billingInfo?.firstName} ${order.billingInfo?.lastName}`,
    },
    documents: order.invoice ? [order.invoice] : [],
    products: order.items?.map((product) => ({
      price: product?.price ? formatAmount(fixedNumber(product?.price)) : `-`,
      skuId: product?.skuId || '-',
      productId: product?.productId || '-',
      vehicleType: product?.details?.vehicleType || '-',
      name: handleDetails(product?.details),
    })),
  };
};
export const handleTransactions = (data = []) => {
  if (!data || !data.length) {
    return [];
  }
  return data.map((item) => ({
    ...item,
    providerTransactionId: item.providerTransactionId,
    userId: item.userId,
    status: item?.status,
    failureReason: item?.failureReason || '-',
    amount: item?.amount || '',
    transactionId: item?.id || '',
  }));
};
