import moment from 'moment';
import { formatDate, sortArray } from '@utils';
import { BOARDS_ID } from '@constants';

const _computeOfferRequestColor = (offerRequestDate) => {
  if (offerRequestDate) {
    const given = moment(offerRequestDate, 'YYYY-MM-DD');
    const current = moment().startOf('day');
    const dayDiff = moment.duration(current.diff(given)).asDays();
    if (dayDiff >= 35) {
      return '#FFA366';
    }
    return '#03AA46';
  }
  if (!offerRequestDate) {
    return '#FF0000';
  }
};

export const handleBuyOutData = (data, assignees) => {
  if (!data?.length) {
    return {};
  }
  let totalFinalPrice = 0;

  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0]}${cur.lastName?.[0]}`.toUpperCase(),
    };
    return acc;
  }, {});

  const tasks = [];
  // eslint-disable-next-line no-unused-vars
  data?.forEach((item, index) => {
    const assignee = assigneesMap[item.assignee] || {};

    if (item?.customFields?.finalPrice?.number) {
      totalFinalPrice += Number(item?.customFields?.finalPrice?.number);
    }
    const newTask = {
      ...item,
      offerRequestDateColor: _computeOfferRequestColor(
        item?.customFields?.offerRequestDate
      ),
      customFields: {
        ...item.customFields,
        billedAt: item?.customFields?.billedAt
          ? formatDate(item?.customFields?.billedAt, 'DD/MM/YYYY')
          : '-',
        finalPrice: item?.customFields?.finalPrice?.number
          ? new Intl.NumberFormat('el-GR', {
              style: 'currency',
              currency: 'EUR',
            }).format(item?.customFields?.finalPrice?.number)
          : '-',
        offerSentDate: item?.customFields?.offerSentDate
          ? formatDate(item?.customFields?.offerSentDate, 'DD/MM/YYYY')
          : '-',
        approvalStatus: !item?.customFields?.approvalStatus
          ? 'Pending'
          : item?.customFields?.approvalStatus,

        loan: item?.customFields?.loan,
        loanContractId: item?.customFields?.loanContractId,
      },
      href: `/buyout/${item?.id}`,
      status: item?.status,
      assignee,
      description: item?.name,
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      userId: item?.customFields?.userId,
    };

    tasks.push(newTask);
  });
  return {
    boardId: BOARDS_ID?.BUY_OUT,
    tasks,
    totalFinalPrice: new Intl.NumberFormat('el-GR', {
      style: 'currency',
      currency: 'EUR',
    }).format(totalFinalPrice),
  };
};

const handleAmmountInstallmentsItem = (newAmountItem) => {
  if (newAmountItem.Key === 'amount') {
    return {
      ...newAmountItem,
      Value: newAmountItem.Value.map((amountValueItem) => ({
        ...amountValueItem,
        Value:
          amountValueItem.Key === 'number'
            ? amountValueItem.Value / 100
            : amountValueItem.Value,
      })),
    };
  }
  return newAmountItem;
};

export const handleBuyOutTaskData = (data) => {
  if (!data) {
    return {};
  }
  const changelog = data?.changelog?.reverse().map((item) => {
    const normalisedNewValue =
      item.fieldName === 'installments'
        ? item.newValue.map((newValue) =>
            newValue.map(handleAmmountInstallmentsItem)
          )
        : item.newValue;

    return { ...item, newValue: normalisedNewValue };
  });

  const task = {
    ...data,
    changelog,
    comments: sortArray(data?.comments, 'createdAt', 'desc')?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.userFullName || entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
    documents: data?.documents?.map((document) => {
      return { ...document, cannotDelete: document?.type === 'BuyoutOffer' };
    }),
  };
  return task;
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${data?.internalId ? `${data?.internalId} -` : '' || ''} ${
      data?.vin ? `${data?.vin} -` : ''
    } ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${
      data?.properties?.transmission
        ? `${data?.properties?.transmission} -`
        : ''
    } ${data?.properties?.fuelType}`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
    licenseIssuanceDate: formatDate(
      data?.procurement?.licenseIssuanceDate,
      'DD-MM-YYYY'
    ),
  };
  return vehicle;
};
export const handleAssigneeData = (data = []) =>
  data
    ?.filter((item) => item.isActive)
    ?.map((item) => ({
      value: item.email,
      label:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label));
