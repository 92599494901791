import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getUrl, showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { serviceVehiclesServices } from '@services';
import { handleServiceVehiclesRequestPayload } from 'helpers/serviceVehicles';
import { LIMIT } from '@constants';
import { URLS } from 'layouts/DrawerLayout/constants';
import { handleServiceVehiclesData } from './utils';

export const useServiceVehiclesOperations = () => {
  const pageSize = LIMIT;

  const router = useRouter();
  const page = router.query?.page || 1;
  const orderBy = router.query?.orderBy || '';
  const order = router.query?.order || '';
  const others = router.query;

  const otherQueries = Object.entries(others)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const serviceVehiclesResponse = useQuery(
    [
      QUERY_KEYS.GET_SERVICE_VEHICLES_KEY,
      {
        page,
        orderBy,
        order,
        otherQueries,
      },
    ],
    () => {
      const payload = handleServiceVehiclesRequestPayload(router);
      return serviceVehiclesServices?.getServiceVehiclesFilters({
        page,
        orderBy,
        order,
        ...payload,
      });
    }
  );

  const serviceVehiclesTypesResponse = useQuery(
    QUERY_KEYS.GET_SERVICE_VEHICLES_TYPES_KEY,
    () => serviceVehiclesServices?.getServiceVehiclesTypes()
  );

  const serviceVehicles = handleServiceVehiclesData({
    serviceVehicles: serviceVehiclesResponse?.data,
    serviceVehiclesTypes: serviceVehiclesTypesResponse?.data?.data,
  });

  const handleSortBy = (sortByModel) => {
    const url = getUrl(
      { ...router.query, ...sortByModel },
      URLS.serviceVehicles
    );
    router.push(url);
  };

  const handleNextPage = (newPage) => {
    router.push(
      getUrl({ ...router.query, page: newPage }, URLS.serviceVehicles)
    );
  };

  useEffect(() => {
    if (serviceVehiclesResponse?.isError) {
      showError(serviceVehiclesResponse.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceVehiclesResponse?.isError]);

  useEffect(() => {
    if (serviceVehiclesTypesResponse?.isError) {
      showError(serviceVehiclesTypesResponse.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceVehiclesTypesResponse?.isError]);

  return {
    page,
    total: serviceVehiclesResponse?.data?.meta?.total || '',
    loading:
      serviceVehiclesResponse?.isFetching ||
      serviceVehiclesTypesResponse?.isFetching,
    serviceVehicles,
    pageSize,
    getServiceVehicles: serviceVehiclesResponse?.refetch,
    nextPage: handleNextPage,
    sortBy: handleSortBy,
  };
};
