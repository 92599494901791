const normalizeGoogleAddress = (googlePlaceServicesData) => {
  const addressComponents = googlePlaceServicesData[0]?.address_components;
  const placeCoordinates = googlePlaceServicesData[0]?.geometry?.location;
  const tempAddress = {};

  if (addressComponents?.length) {
    addressComponents?.forEach((part) => {
      if (
        part?.types?.includes('route') ||
        part?.types?.includes('street_address')
      ) {
        tempAddress.streetAddress = part?.long_name;
      }
      if (part?.types?.includes('street_number')) {
        tempAddress.streetNumber = part?.long_name;
      }
      if (part?.types?.includes('locality')) {
        tempAddress.locality = part?.long_name;
      }
      if (part?.types?.includes('administrative_area_level_1')) {
        tempAddress.region = part?.long_name;
      }
      if (part?.types?.includes('postal_code')) {
        tempAddress.zipCode = part?.long_name;
      }
      if (part?.types?.includes('country')) {
        tempAddress.country = part?.long_name;
      }
    });
    tempAddress.latitude = placeCoordinates?.lat;
    tempAddress.longitude = placeCoordinates?.lng;
  }

  return tempAddress;
};

export default normalizeGoogleAddress;
