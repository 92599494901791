import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
  servicePointsServices,
} from '@services';
import { SERVICE_POINTS_PAGE_SIZE } from '@constants';
import {
  handleArnmMaintenanceTaskData,
  handleAssigneeData,
  handleServicePointsTaskData,
  handleVehicleData,
} from './utils';

export const useArnmMaintenanceTaskOperations = () => {
  const router = useRouter();
  const arnmMaintenanceTaskId = router.query?.arnmMaintenanceTaskId;

  const arnmMaintenanceTaskResponse = useQuery(
    [QUERY_KEYS.GET_ARNM_MAINTENANCE_TASK_KEY, arnmMaintenanceTaskId],
    () => boardsServices.getTaskById(arnmMaintenanceTaskId),
    {
      enabled: !!arnmMaintenanceTaskId,
    }
  );

  const carId = arnmMaintenanceTaskResponse?.data?.data?.customFields?.carId;

  const vehicleResponse = useQuery(
    [QUERY_KEYS.GET_VEHICLE_BY_INTERNAL_ID_KEY, carId],
    () => fleetVehiclesServices.getVehicleByInternalId(carId),
    {
      enabled: arnmMaintenanceTaskResponse.isFetched && !!carId,
    }
  );

  const vehicle = handleVehicleData(vehicleResponse?.data?.data);

  const servicePointsResponse = useQuery(
    QUERY_KEYS.GET_SERVICE_POINTS_KEY,
    () =>
      servicePointsServices.getServicePoints({
        page: 1,
        pageSize: SERVICE_POINTS_PAGE_SIZE,
      })
  );

  const servicePoints = handleServicePointsTaskData(
    servicePointsResponse?.data?.data
  );

  const assigneesResponse = useQuery(
    QUERY_KEYS.GET_ARNM_MAINTENANCE_ASSIGNEES_KEY,
    () =>
      agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      })
  );

  const assignees = handleAssigneeData(assigneesResponse?.data?.data);

  const arnmMaintenanceTask = handleArnmMaintenanceTaskData(
    arnmMaintenanceTaskResponse?.data?.data,
    servicePoints,
    assignees
  );

  const updateTask = async (payload = {}) => {
    try {
      if (payload?.cardId) {
        const id = payload?.cardId;
        delete payload?.cardId;
        await boardsServices.updateTask(id, payload);
      } else {
        await boardsServices.updateTask(payload?.id, payload);
      }
    } catch (error) {
      showError(error);
    }
  };

  const loading =
    arnmMaintenanceTaskResponse?.isFetching ||
    vehicleResponse?.isFetching ||
    servicePointsResponse?.isFetching ||
    assigneesResponse?.isFetching;

  useEffect(() => {
    if (arnmMaintenanceTaskResponse?.isError) {
      showError(arnmMaintenanceTaskResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arnmMaintenanceTaskResponse?.isError]);

  useEffect(() => {
    if (vehicleResponse?.isError) {
      showError(vehicleResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleResponse?.isError]);

  useEffect(() => {
    if (servicePointsResponse?.isError) {
      showError(servicePointsResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicePointsResponse?.isError]);

  useEffect(() => {
    if (assigneesResponse?.isError) {
      showError(assigneesResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneesResponse?.isError]);

  return {
    loading,
    task: arnmMaintenanceTask,
    taskId: arnmMaintenanceTaskId,
    getArnmMaintenanceTask: arnmMaintenanceTaskResponse.refetch,
    assignees,
    updateTask,
    servicePoints,
    vehicle,
  };
};
