import {
  ELECTRIC_VEHICLES_TYPES,
  HANDOVER_CARS_SIDE,
  SUBSCRIPTIONS_TYPES,
  SUBSCRIPTIONS_STATUSES,
} from '@constants';
import {
  formatDate,
  formatAmount,
  formatVehicleType,
  formatVehicleStage,
  timeFromNow,
  duration,
} from '@utils';
import moment from 'moment';

const getHumanReadableDuration = (iso8601duration) => {
  const momentDuration = moment.duration(iso8601duration);

  const years = momentDuration.years();
  const months = momentDuration.months();

  let result = years ? `${years} Year${years > 1 ? 's' : ''} ` : '';
  result = months
    ? `${result}${result ? 'and ' : ''}${months} Month${months > 1 ? 's' : ''} `
    : result;

  return result;
};

const _estimatedDurations = () => {
  const options = [];
  for (let i = 0; i < 81; i++) {
    const text = getHumanReadableDuration(`P${i}M`);
    options.push({ value: `P${i}M`, text });
  }
  return options;
};

const _compute19thMonth = (date) => {
  return moment(date).add(19, 'M').format('MM YYYY');
};

export const handleSubscriptions = (array) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, innerArray) => {
    const data = {
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY')
        : '-',
      vehicle: item?.vehicle
        ? `${item?.vehicle?.manufacturer || ''} - ${item?.vehicle?.model || ''}`
        : '-',
      plate: item?.vehicle?.plate || '-',
      amount: item?.rules?.[0]?.amount?.value
        ? formatAmount(item?.rules?.[0]?.amount?.value)
        : '-',
      pipedriveId: item?.pipedriveId || '-',
      pipedriveUrl: item?.pipedriveUrl,
      id: item?.id || '-',
      internalId: item?.internal_id,
      type: item?.type,
      status: item?.status,
      // as owner we define the subscriber who has subscription with role === 1 (owner)
      owner:
        item?.subscribers?.find((subscriber) =>
          subscriber?.subscriptions?.find(
            (subscription) =>
              subscription.role === 1 && subscription.id === item?.id
          )
        )?.email || '-',
      carId: item?.vehicle?.id,
      carInternalId: item?.vehicle?.internalId,
    };
    innerArray[index] = data;

    return innerArray;
  });
  return array;
};

export const handleSubscriptionSteps = (steps) => {
  let frontStep = steps?.vehicleChecks
    ?.filter((step) => {
      return step.side === HANDOVER_CARS_SIDE.FRONT;
    })
    ?.map((side) => {
      return {
        title: 'Front Side',
        checkList: [
          {
            title: 'Car Hood',
            value: side?.checklist?.carHood,
          },
          {
            title: 'Front Bumper',
            value: side?.checklist?.frontBumper,
          },
          {
            title: 'Wind Screen',
            value: side?.checklist?.windscreen,
          },
        ],
        comments: side?.comments,
        images: side?.images,
      };
    })
    .pop();

  if (!frontStep) {
    frontStep = {
      title: 'Front Side',
      checkList: [
        {
          title: 'Car Hood',
          value: false,
        },
        {
          title: 'Front Bumper',
          value: false,
        },
        {
          title: 'Wind Screen',
          value: false,
        },
      ],
      comments: '',
      images: [],
    };
  }

  let backStep = steps?.vehicleChecks
    ?.filter((step) => {
      return step.side === HANDOVER_CARS_SIDE.BACK;
    })
    ?.map((side) => {
      return {
        title: 'Back Side',
        checkList: [
          {
            title: 'Back Bumper',
            value: side?.checklist?.backBumper,
          },
          {
            title: 'Car Hood',
            value: side?.checklist?.carHood,
          },
          {
            title: 'Wind Screen',
            value: side?.checklist?.windscreen,
          },
        ],
        comments: side?.comments,
        images: side?.images,
      };
    })
    .pop();

  if (!backStep) {
    backStep = {
      title: 'Back Side',
      checkList: [
        {
          title: 'Back Bumper',
          value: false,
        },
        {
          title: 'Car Hood',
          value: false,
        },
        {
          title: 'Wind Screen',
          value: false,
        },
      ],
      comments: '',
      images: [],
    };
  }

  let topStep = steps?.vehicleChecks
    ?.filter((step) => {
      return step.side === HANDOVER_CARS_SIDE.ABOVE;
    })
    ?.map((side) => {
      return {
        title: 'Top Side',
        checkList: [
          {
            title: 'Top',
            value: side?.checklist?.top,
          },
        ],
        comments: side?.comments,
        images: side?.images,
      };
    })
    .pop();

  if (!topStep) {
    topStep = {
      title: 'Top Side',
      checkList: [
        {
          title: 'Top',
          value: false,
        },
      ],
      comments: '',
      images: [],
    };
  }

  let leftStep = steps?.vehicleChecks
    ?.filter((step) => {
      return step.side === HANDOVER_CARS_SIDE.LEFT;
    })
    ?.map((side) => {
      return {
        title: 'Left Side',
        checkList: [
          {
            title: 'Back Door',
            value: side?.checklist?.backDoor,
          },
          {
            title: 'Back Wheel',
            value: side?.checklist?.backWheel,
          },
          {
            title: 'Front Door',
            value: side?.checklist?.frontDoor,
          },
          {
            title: 'Front Wheel',
            value: side?.checklist?.frontWheel,
          },
        ],
        comments: side?.comments,
        images: side?.images,
      };
    })
    .pop();

  if (!leftStep) {
    leftStep = {
      title: 'Left Side',
      checkList: [
        {
          title: 'Back Door',
          value: false,
        },
        {
          title: 'Back Wheel',
          value: false,
        },
        {
          title: 'Front Door',
          value: false,
        },
        {
          title: 'Front Wheel',
          value: false,
        },
      ],
      comments: '',
      images: [],
    };
  }

  let rightStep = steps?.vehicleChecks
    ?.filter((step) => {
      return step.side === HANDOVER_CARS_SIDE.RIGHT;
    })
    ?.map((side) => {
      return {
        title: 'Right Side',
        checkList: [
          {
            title: 'Back Door',
            value: side?.checklist?.backDoor,
          },
          {
            title: 'Back Wheel',
            value: side?.checklist?.backWheel,
          },
          {
            title: 'Front Door',
            value: side?.checklist?.frontDoor,
          },
          {
            title: 'Front Wheel',
            value: side?.checklist?.frontWheel,
          },
        ],
        comments: side?.comments,
        images: side?.images,
      };
    })
    .pop();

  if (!rightStep) {
    rightStep = {
      title: 'Right Side',
      checkList: [
        {
          title: 'Back Door',
          value: false,
        },
        {
          title: 'Back Wheel',
          value: false,
        },
        {
          title: 'Front Door',
          value: false,
        },
        {
          title: 'Front Wheel',
          value: false,
        },
      ],
      comments: '',
      images: [],
    };
  }

  let interiorStep = steps?.vehicleChecks

    ?.filter((step) => {
      return step.side === HANDOVER_CARS_SIDE.INTERIOR;
    })
    ?.map((side) => {
      return {
        title: 'Interior',
        checkList: [
          {
            title: 'Auto Show',
            value: side?.checklist?.autoShow,
          },
          {
            title: 'Floor Mats',
            value: side?.checklist?.floorMats,
          },
          {
            title: 'Seats',
            value: side?.checklist?.seats,
          },
          {
            title: 'Top',
            value: side?.checklist?.top,
          },
        ],
        comments: side?.comments,
        images: side?.images,
      };
    })
    .pop();

  if (!interiorStep) {
    interiorStep = {
      title: 'Interior',
      checkList: [
        {
          title: 'Auto Show',
          value: false,
        },
        {
          title: 'Floor Mats',
          value: false,
        },
        {
          title: 'Seats',
          value: false,
        },
        {
          title: 'Top',
          value: false,
        },
      ],
      comments: '',
      images: [],
    };
  }

  const observationsStep = {
    title: 'Additional Observations',
    comments: steps?.extraNotes?.comments || '',
    images: steps?.extraNotes?.images || [],
  };

  const data = [];
  if (frontStep) {
    data?.push(frontStep);
  }
  if (leftStep) {
    data?.push(leftStep);
  }
  if (backStep) {
    data?.push(backStep);
  }
  if (rightStep) {
    data?.push(rightStep);
  }
  if (topStep) {
    data?.push(topStep);
  }
  if (interiorStep) {
    data?.push(interiorStep);
  }
  if (observationsStep) {
    data?.push(observationsStep);
  }

  return data;
};

export const handleSubscription = (subscription, linkedSubscriptions) => {
  let owner = {};
  const data = { ...subscription };
  const ruleLength = data?.rules?.length;
  let activeRule = null;
  const vehicle = data?.vehicle;
  if (ruleLength) {
    activeRule = data?.rules?.[ruleLength - 1];
  }
  data.activeRule = activeRule;
  data.amount = activeRule?.amount;

  if (activeRule) {
    activeRule.fees = activeRule?.fees?.map((fee) => {
      fee.formatedAmount = fee?.amount ? formatAmount(fee?.amount?.value) : '-';
      fee.createdAt = fee?.createdAt
        ? formatDate(fee?.createdAt, 'DD/MM/YYYY')
        : '-';
      fee.updatedAt = fee?.updatedAt
        ? formatDate(fee?.updatedAt, 'DD/MM/YYYY')
        : '-';
      fee.status = fee?.status || '-';
      fee.invoices = fee.invoices || '-';
      return fee;
    });
  }
  data.subScriptionInformation = {
    type: {
      label: 'Type',
      renderedValue: SUBSCRIPTIONS_TYPES[data?.type],
      onEditField: null,
    },
    status: {
      label: 'Status',
      renderedValue: SUBSCRIPTIONS_STATUSES[data?.status],
      onEditField: null,
    },
    id: {
      label: 'ID',
      renderedValue: data?.id,
      onEditField: null,
    },
    internalId: {
      label: 'Internal ID',
      renderedValue: data?.internal_id || '-',
      onEditField: null,
    },
    contractControl: {
      label: 'Contract Control:',
      hasCheckBox: true,
      renderedValue: data?.contractControl,
      onEditField: null,
    },
    createdAt: {
      label: 'Created At:',
      renderedValue: activeRule?.createdAt
        ? formatDate(activeRule?.createdAt, 'DD/MM/YYYY HH:mm:ss')
        : '-',
      onEditField: null,
    },
    updatedAt: {
      label: 'Updated At:',
      renderedValue: activeRule?.updatedAt
        ? formatDate(activeRule?.updatedAt, 'DD/MM/YYYY HH:mm:ss')
        : '-',
      onEditField: null,
    },
    pipedriveID: {
      label: 'Pipedrive ID:',
      renderedValue: data?.pipedriveId || '-',
      link: data?.pipedriveUrl || '',
      target: '_blank',
      onEditField: null,
    },
  };
  data.subscription = {
    billingPeriod: {
      label: 'Billing Period:',
      renderedValue: activeRule?.billing?.period
        ? getHumanReadableDuration(activeRule?.billing?.period)
        : '-',
      onEditField: null,
    },
    scheduledByMonthDay: {
      label: 'Payment Due Date:',
      renderedValue: activeRule?.billing?.scheduledByMonthDay || '-',
      onEditField: null,
    },
    month: {
      canEdited: false,
      label: '19th Month:',
      renderedValue:
        data?.deliveryDate && data?.deliveryDate !== '0001-01-01T00:00:00Z'
          ? _compute19thMonth(data?.deliveryDate)
          : '-',
      onEditField: null,
    },
    promisedDeliveryDate: {
      canEdited: true,
      type: 'dateTime',
      label: 'Promised Delivery Date:',
      renderedValue:
        data?.promisedDeliveryDate &&
        data?.promisedDeliveryDate !== '0001-01-01T00:00:00Z'
          ? formatDate(data?.promisedDeliveryDate, 'DD/MM/YYYY HH:mm')
          : '-',
      defaultValue:
        data?.promisedDeliveryDate !== '0001-01-01T00:00:00Z'
          ? data?.promisedDeliveryDate
          : null,
      onEditField: null,
    },
    promisedReturnDate: {
      canEdited: true,
      isClearable: true,
      type: 'dateTime',
      label: 'Promised Return Date:',
      renderedValue:
        data?.promisedReturnDate &&
        data?.promisedReturnDate !== '0001-01-01T00:00:00Z'
          ? formatDate(data?.promisedReturnDate, 'DD/MM/YYYY HH:mm')
          : '-',
      defaultValue:
        data?.promisedReturnDate !== '0001-01-01T00:00:00Z'
          ? data?.promisedReturnDate
          : null,
      onEditField: null,
    },

    duration: {
      canEdited: true,
      label: 'Contract Duration:',
      type: 'dropdown',
      placeholder: '',
      defaultValue: activeRule?.estimatedDuration,
      options: _estimatedDurations(),
      renderedValue: activeRule?.estimatedDuration
        ? getHumanReadableDuration(activeRule?.estimatedDuration)
        : '-',
      onEditField: null,
      rules: {
        required: '* This field is required',
      },
      fieldWrapperStyle: {
        width: '100%',
      },
    },
    endContactDate: {
      canEdited: false,
      label: 'End Contract Date:',
      type: 'date',
      placeholder: '',
      renderedValue:
        data?.endContractDate &&
        data?.endContractDate !== '0001-01-01T00:00:00Z'
          ? formatDate(data?.endContractDate, 'DD/MM/YYYY HH:mm')
          : '-',
      onEditField: null,
      rules: {
        required: '* This field is required',
      },
      fieldWrapperStyle: {
        width: '100%',
      },
    },
    durationFromDeliveryDate: {
      canEdited: false,
      label: 'Duration:',
      type: 'dropdown',
      placeholder: '',
      defaultValue: '',
      renderedValue:
        // eslint-disable-next-line no-nested-ternary
        !data?.returnDate && !!data?.deliveryDate
          ? timeFromNow(data?.deliveryDate)
          : !!data?.deliveryDate && !!data?.returnDate
          ? duration(data?.deliveryDate, data?.returnDate)
          : '-',
      onEditField: null,
      rules: {
        required: '* This field is required',
      },
      fieldWrapperStyle: {
        width: '100%',
      },
    },
    erfudd: {
      canEdited: false,
      label: 'Erfudd:',
      renderedValue:
        data?.erfudd && data?.erfudd !== '0001-01-01T00:00:00Z'
          ? formatDate(data?.erfudd, 'DD/MM/YYYY HH:mm')
          : '-',
      onEditField: null,
    },
    outstandingChargesDate: {
      canEdited: true,
      type: 'date',
      label: 'Outstanding Charges Date:',
      renderedValue:
        data?.outstandingChargesDate &&
        data?.outstandingChargesDate !== '0001-01-01T00:00:00Z'
          ? formatDate(data?.outstandingChargesDate, 'DD/MM/YYYY')
          : '-',
      defaultValue: data?.outstandingChargesDate,
    },
    outstandingChargesTotalAmount: {
      canEdited: true,
      type: 'number',
      label: 'Outstanding Charges Total Amount:',
      renderedValue: data?.outstandingChargesTotalAmount || '-',
      defaultValue: data?.outstandingChargesTotalAmount,
    },
  };

  data.vehicleInformation = {
    vehicle: {
      label: 'Vehicle:',
      renderedValue: `${vehicle?.manufacturer} - ${vehicle?.model}`,
      link: `/vehicles/${vehicle?.id}`,
    },
    type: {
      label: 'Type:',
      renderedValue: formatVehicleType(vehicle?.type) || '-',
    },
    plate: {
      label: 'Plate:',
      renderedValue: vehicle?.plate || '-',
    },
    carId: {
      label: 'Car ID:',
      renderedValue: vehicle?.internalId || '-',
    },
    createdAt: {
      label: 'Created:',
      renderedValue: vehicle?.createdAt
        ? formatDate(vehicle?.createdAt, 'DD/MM/YYYY')
        : '-',
    },
    transmission: {
      label: 'Transmission:',
      renderedValue: vehicle?.properties?.transmission || '-',
    },
    color: {
      label: 'Color:',
      renderedValue: vehicle?.properties?.color || '-',
    },
    fuelType: {
      label: 'Fuel Type:',
      renderedValue: vehicle?.properties?.fuelType || '-',
    },
    stage: {
      label: 'Stage:',
      renderedValue: vehicle?.procurement?.currentStage
        ? formatVehicleStage(vehicle?.procurement?.currentStage)
        : '-',
    },
    location: {
      label: 'Location:',
      renderedValue: vehicle?.procurement?.currentLocation
        ? vehicle?.procurement?.currentLocation
        : '-',
    },
  };
  data.userLanguage = 'el';
  data.subscribers = data.subscribers?.map((subscriber) => {
    subscriber.fullName = `${subscriber?.firstName || ''} ${
      subscriber.lastName || ''
    }`;
    const currentSubscription = subscriber?.subscriptions
      ?.filter((item) => {
        return item?.id === data?.id;
      })
      ?.pop();

    subscriber.activeDriver = currentSubscription?.activeDriver;

    subscriber.type = currentSubscription?.type;
    subscriber.role = currentSubscription?.role;
    subscriber.createdAt = subscriber?.createdAt
      ? formatDate(subscriber?.createdAt, 'DD/MM/YYYY')
      : '-';
    subscriber.updatedAt = subscriber?.createdAt
      ? formatDate(subscriber?.updatedAt, 'DD/MM/YYYY')
      : '-';

    if (currentSubscription) {
      owner = subscriber;
      owner.type = currentSubscription?.type;
    }
    if (currentSubscription?.role === 1) {
      data.userLanguage = subscriber?.lang || 'el';
    }
    return subscriber;
  });
  // TODO: comment out when contact person gets unblocked
  // .filter(
  //   (subscriber) => subscriber.role !== SUBSCRIBER_ROLES.contactPerson.text
  // );

  data.individualBillingInfo = null;
  data.businessBillingInfo = null;

  if (
    !data?.billingInfo?.firstName &&
    !data?.billingInfo?.lastName &&
    !data?.billingInfo?.vat &&
    !data?.billingInfo?.companyName &&
    !data?.billingInfo?.doi &&
    !data?.billingInfo?.companyName &&
    !data?.billingInfo?.doi &&
    !data?.billingInfo?.activity &&
    !data?.billingInfo?.phoneNumber &&
    !data?.billingInfo?.email &&
    !data?.billingInfo?.address &&
    !data?.billingInfo?.postalCode
  ) {
    data.emptyBillingInfo = {
      contract: {
        label: 'Contract:',
        type: 'dropdown',
        placeholder: '',
        defaultValue: 'Ιδιώτης',
        options: [
          {
            text: 'Ιδιώτης',
            value: 'Ιδιώτης',
          },
          {
            text: 'Εταιρεία',
            value: 'Εταιρεία',
          },
        ],
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hiddenToJSONList: true,
      },
      firstName: {
        label: 'First Name:',
        renderedValue: data?.billingInfo?.firstName || '-',
        defaultValue: data?.billingInfo?.firstName,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      lastName: {
        label: 'Last Name:',
        renderedValue: data?.billingInfo?.lastName || '-',
        defaultValue: data?.billingInfo?.lastName,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      vat: {
        label: 'Tax code:',
        renderedValue: data?.billingInfo?.vat || '-',
        defaultValue: data?.billingInfo?.vat,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      companyName: {
        label: 'Business Name:',
        renderedValue: data?.billingInfo?.companyName || '-',
        defaultValue: data?.billingInfo?.companyName,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: true,
        hiddenToJSONList: true,
      },
      doi: {
        label: 'D.O.Y:',
        renderedValue: data?.billingInfo?.doi || '-',
        defaultValue: data?.billingInfo?.doi,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      activity: {
        label: 'Activity:',
        renderedValue: data?.billingInfo?.activity || '-',
        defaultValue: data?.billingInfo?.activity,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      phoneNumber: {
        label: 'Phone Number:',
        renderedValue: data?.billingInfo?.phoneNumber || '-',
        defaultValue: data?.billingInfo?.phoneNumber,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      email: {
        label: 'E-mail:',
        renderedValue: data?.billingInfo?.email || '-',
        defaultValue: data?.billingInfo?.email,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
          pattern: {
            value: `${/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}`,
            message: 'invalid email address',
          },
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      address: {
        label: 'Street Address:',
        renderedValue: data?.billingInfo?.address || '-',
        defaultValue: data?.billingInfo?.address,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      postalCode: {
        label: 'Postal Code:',
        renderedValue: data?.billingInfo?.postalCode || '-',
        defaultValue: data?.billingInfo?.postalCode,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
    };
  } else if (data?.billingInfo?.companyName) {
    data.businessBillingInfo = {
      firstName: {
        label: 'First Name:',
        renderedValue: data?.billingInfo?.firstName || '-',
        defaultValue: data?.billingInfo?.firstName,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      lastName: {
        label: 'Last Name:',
        renderedValue: data?.billingInfo?.lastName || '-',
        defaultValue: data?.billingInfo?.lastName,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      vat: {
        label: 'Tax code:',
        renderedValue: data?.billingInfo?.vat || '-',
        defaultValue: data?.billingInfo?.vat,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
        // TODO: disable it when we have a permission logic
        // disabled: !!data?.billingInfo?.vat,
      },
      companyName: {
        label: 'Business Name:',
        renderedValue: data?.billingInfo?.companyName || '-',
        defaultValue: data?.billingInfo?.companyName,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      doi: {
        label: 'D.O.Y:',
        renderedValue: data?.billingInfo?.doi || '-',
        defaultValue: data?.billingInfo?.doi,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      activity: {
        label: 'Activity:',
        renderedValue: data?.billingInfo?.activity || '-',
        defaultValue: data?.billingInfo?.activity,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      phoneNumber: {
        label: 'Phone Number:',
        renderedValue: data?.billingInfo?.phoneNumber || '-',
        defaultValue: data?.billingInfo?.phoneNumber,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      email: {
        label: 'E-mail:',
        renderedValue: data?.billingInfo?.email || '-',
        defaultValue: data?.billingInfo?.email,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
          pattern: {
            value: `${/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}`,
            message: 'invalid email address',
          },
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      address: {
        label: 'Street Address:',
        renderedValue: data?.billingInfo?.address || '-',
        defaultValue: data?.billingInfo?.address,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      postalCode: {
        label: 'Postal Code:',
        renderedValue: data?.billingInfo?.postalCode || '-',
        defaultValue: data?.billingInfo?.postalCode,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
    };
  } else {
    data.individualBillingInfo = {
      firstName: {
        label: 'First Name:',
        renderedValue: data?.billingInfo?.firstName || '-',
        defaultValue: data?.billingInfo?.firstName,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      lastName: {
        label: 'Last Name:',
        renderedValue: data?.billingInfo?.lastName || '-',
        defaultValue: data?.billingInfo?.lastName,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      vat: {
        label: 'Tax code:',
        renderedValue: data?.billingInfo?.vat || '-',
        defaultValue: data?.billingInfo?.vat,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
        // TODO: disable it when we have a permission logic
        // disabled: !!data?.billingInfo?.vat,
      },
      doi: {
        label: 'D.O.Y:',
        renderedValue: data?.billingInfo?.doi || '-',
        defaultValue: data?.billingInfo?.doi,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      activity: {
        label: 'Activity:',
        renderedValue: data?.billingInfo?.activity || 'Ιδιώτης',
        defaultValue: data?.billingInfo?.activity || 'Ιδιώτης',
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      phoneNumber: {
        label: 'Phone Number:',
        renderedValue: data?.billingInfo?.phoneNumber || '-',
        defaultValue: data?.billingInfo?.phoneNumber,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      address: {
        label: 'Street Address:',
        renderedValue: data?.billingInfo?.address || '-',
        defaultValue: data?.billingInfo?.address,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      email: {
        label: 'E-mail:',
        renderedValue: data?.billingInfo?.email || '-',
        defaultValue: data?.billingInfo?.email,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
          pattern: {
            value: `${/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i}`,
            message: 'invalid email address',
          },
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
      postalCode: {
        label: 'Postal Code:',
        renderedValue: data?.billingInfo?.postalCode || '-',
        defaultValue: data?.billingInfo?.postalCode,
        onEditField: null,
        canEdited: true,
        type: 'text',
        placeholder: '',
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
        hidden: false,
      },
    };
  }

  data.deliveryInfoFinalChecks = [
    {
      insuranceContract: {
        title: 'Insurance Contract',
        value: data?.deliveryInfo?.finalChecklist?.insuranceContract,
      },
      copyOfTheVehiclesLicense: {
        title: `Copy of the Vehicle's License`,
        value: data?.deliveryInfo?.finalChecklist?.copyOfTheVehiclesLicense,
      },
    },
    {
      instakit: {
        title: 'instakit',
        value: data?.deliveryInfo?.finalChecklist?.instakit,
      },
      pharmacyWarningTriangle: {
        title: `Pharmacy Lit & Warning Triangle`,
        value: data?.deliveryInfo?.finalChecklist?.pharmacyWarningTriangle,
      },
      fireExtinguisher: {
        title: 'Fire Extinguisher',
        value: data?.deliveryInfo?.finalChecklist?.fireExtinguisher,
      },
      foam: {
        title: `Foam`,
        value: data?.deliveryInfo?.finalChecklist?.foam,
      },
    },
    {
      spareTire: {
        title: 'Spare Tire',
        value: data?.deliveryInfo?.finalChecklist?.spareTire,
      },
      carMats: {
        title: `Car Mats`,
        value: data?.deliveryInfo?.finalChecklist?.carMats,
      },
      shelf: {
        title: 'Shelf',
        value: data?.deliveryInfo?.finalChecklist?.shelf,
      },
      skidChain: {
        title: `Skid Chain`,
        value: data?.deliveryInfo?.finalChecklist?.skidChain,
      },
    },
  ];

  if (
    ELECTRIC_VEHICLES_TYPES?.includes(
      data?.vehicle?.sku?.engine?.type?.toLowerCase()
    )
  ) {
    data.deliveryInfoFinalChecks = [
      ...data.deliveryInfoFinalChecks,
      {
        chargingCable: {
          title: 'Charging Cable',
          value: data?.deliveryInfo?.finalChecklist?.chargingCable,
        },
      },
    ];
  }

  data.returnInfoFinalChecks = [
    {
      instakit: {
        title: 'instakit',
        value: data?.returnInfo?.finalChecklist?.instakit,
      },
      pharmacyWarningTriangle: {
        title: `Pharmacy Lit & Warning Triangle`,
        value: data?.returnInfo?.finalChecklist?.pharmacyWarningTriangle,
      },
      fireExtinguisher: {
        title: 'Fire Extinguisher',
        value: data?.returnInfo?.finalChecklist?.fireExtinguisher,
      },
      foam: {
        title: `Foam`,
        value: data?.returnInfo?.finalChecklist?.foam,
      },
    },
    {
      spareTire: {
        title: 'Spare Tire',
        value: data?.returnInfo?.finalChecklist?.spareTire,
      },
      carMats: {
        title: `Car Mats`,
        value: data?.returnInfo?.finalChecklist?.carMats,
      },
      shelf: {
        title: 'Shelf',
        value: data?.returnInfo?.finalChecklist?.shelf,
      },
      skidChain: {
        title: `Skid Chain`,
        value: data?.returnInfo?.finalChecklist?.skidChain,
      },
    },
  ];

  if (
    ELECTRIC_VEHICLES_TYPES?.includes(
      data?.vehicle?.sku?.engine?.type?.toLowerCase()
    )
  ) {
    data.returnInfoFinalChecks = [
      ...data.returnInfoFinalChecks,
      {
        spareTire: {
          title: 'Charging Cable',
          value: data?.returnInfo?.finalChecklist?.chargingCable,
        },
      },
    ];
  }
  data.deliveryInfoVehicleDetails = [
    {
      title: '',
      fields: [
        {
          title: 'Promised Delivery Date:',
          renderedValue: data?.promisedDeliveryDate
            ? formatDate(data?.promisedDeliveryDate, 'DD/MM/YYYY HH:mm')
            : '-',
        },
        {
          title: 'Delivery Date:',
          renderedValue: data?.deliveryDate
            ? formatDate(data?.deliveryDate, 'DD/MM/YYYY HH:mm')
            : '-',
        },
        {
          title: 'Delivery Point:',
          renderedValue: data?.deliveryInfo?.location || '-',
        },
        {
          title: 'Delivery driver:',
          renderedValue: data?.deliveryInfo?.userName || '-',
        },
        {
          title: `Km:`,
          renderedValue: data?.deliveryInfo?.kmFuelTiresCheck?.km || '-',
          value: data?.deliveryInfo?.kmFuelTiresCheck?.km,
          canEdited: true,
          key: 'km',
        },
        {
          title: 'Fuel:',
          key: 'fuel',
          renderedValue: data?.deliveryInfo?.kmFuelTiresCheck?.fuel || '-',
          value: data?.deliveryInfo?.kmFuelTiresCheck?.fuel,
        },
      ],
    },
    {
      title: `Tires`,
      fields: [
        {
          title: 'Position:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[0]?.position || '-',
        },
        {
          title: 'Dimensions:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[0]?.dimensions || '-',
        },
        {
          title: 'DOT:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[0]?.dot || '-',
        },
        {
          title: 'Position:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[1]?.position || '-',
        },
        {
          title: 'Dimensions:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[1]?.dimensions || '-',
        },
        {
          title: 'DOT:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[1]?.dot || '-',
        },
        {
          title: 'Position:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[2]?.position || '-',
        },
        {
          title: 'Dimensions:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[2]?.dimensions || '-',
        },
        {
          title: 'DOT:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[2]?.dot || '-',
        },
        {
          title: 'Position:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[3]?.position || '-',
        },
        {
          title: 'Dimensions:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[3]?.dimensions || '-',
        },
        {
          title: 'DOT:',
          renderedValue:
            data?.deliveryInfo?.kmFuelTiresCheck?.tires?.[3]?.dot || '-',
        },
      ],
    },
  ];

  data.returnInfoVehicleDetails = [
    {
      title: '',
      fields: [
        {
          title: 'Promised Return Date:',
          renderedValue:
            data?.promisedReturnDate &&
            data?.promisedReturnDate !== '0001-01-01T00:00:00Z'
              ? formatDate(data?.promisedReturnDate, 'DD/MM/YYYY HH:mm')
              : '-',
        },

        {
          title: 'Return Date:',
          renderedValue:
            data?.returnDate && data?.returnDate !== '0001-01-01T00:00:00Z'
              ? formatDate(data?.returnDate, 'DD/MM/YYYY HH:mm')
              : '-',
        },
        {
          title: 'Return Point:',
          renderedValue: data?.returnInfo?.location || '-',
        },
        {
          title: 'Return driver:',
          renderedValue: data?.returnInfo?.userName || '',
        },
        {
          title: `Km:`,
          renderedValue: data?.returnInfo?.kmFuelTiresCheck?.km || '-',
        },
        {
          title: 'Fuel:',
          key: 'fuel',
          renderedValue: data?.returnInfo?.kmFuelTiresCheck?.fuel || '-',
          value: data?.returnInfo?.kmFuelTiresCheck?.fuel,
        },
      ],
    },
    {
      title: `Tires`,
      fields: [
        {
          title: 'Position:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[0]?.position || '-',
        },
        {
          title: 'Dimensions:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[0]?.dimensions || '-',
        },
        {
          title: 'DOT:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[0]?.dot || '-',
        },
        {
          title: 'Position:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[1]?.position || '-',
        },
        {
          title: 'Dimensions:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[1]?.dimensions || '-',
        },
        {
          title: 'DOT:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[1]?.dot || '-',
        },
        {
          title: 'Position:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[2]?.position || '-',
        },
        {
          title: 'Dimensions:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[2]?.dimensions || '-',
        },
        {
          title: 'DOT:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[2]?.dot || '-',
        },
        {
          title: 'Position:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[3]?.position || '-',
        },
        {
          title: 'Dimensions:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[3]?.dimensions || '-',
        },
        {
          title: 'DOT:',
          renderedValue:
            data?.returnInfo?.kmFuelTiresCheck?.tires?.[3]?.dot || '-',
        },
      ],
    },
  ];

  data.deliveryInspectionInfoDetails = [
    {
      title: '',
      fields: [
        {
          title: 'Inspection Date',
          renderedValue: data?.deliveryInspectionInfo?.date
            ? formatDate(data?.deliveryInspectionInfo?.date, 'DD/MM/YYYY HH:mm')
            : '-',
        },
        {
          title: 'Inspection Point',
          renderedValue: data?.deliveryInspectionInfo?.point || '-',
        },
      ],
    },
  ];

  data.returnInspectionInfoDetails = [
    {
      title: '',
      fields: [
        {
          title: 'Inspection Date',
          renderedValue: data?.returnInspectionInfo?.date
            ? formatDate(data?.returnInspectionInfo?.date, 'DD/MM/YYYY HH:mm')
            : '-',
        },
        {
          title: 'Inspection Point',
          renderedValue: data?.returnInspectionInfo?.point || '-',
        },
      ],
    },
  ];

  data.deliveryInfoSides = handleSubscriptionSteps(data?.deliveryInfo);
  data.returnInfoSides = handleSubscriptionSteps(data?.returnInfo);

  data.deliveryInfoExtraNotes = data?.deliveryInfo?.extraNotes || {};
  data.returnInfoExtraNotes = data?.returnInfo?.extraNotes || {};
  data.deliveryInfoTireImages =
    data?.deliveryInfo?.kmFuelTiresCheck?.images || [];
  data.returnInfoTireImages = data?.returnInfo?.kmFuelTiresCheck?.images || [];

  data.returnInfoLeasingContract = {
    fileName: data?.returnInfo?.tenancyAgreement?.filename,
    url: data?.returnInfo?.tenancyAgreement?.url,
  };

  data.deliveryInfoLeasingContract = {
    fileName: data?.deliveryInfo?.tenancyAgreement?.filename,
    url: data?.deliveryInfo?.tenancyAgreement?.url,
  };

  data.activeRule = activeRule;
  data.cannotMakeDeliveryReason =
    !data?.billingInfo?.firstName &&
    'Please make sure that the Billing Information of the Subscription has been filled';
  const billingInfoORKmMissing =
    !data?.billingInfo?.firstName ||
    (data?.deliveryInfo?.kmFuelTiresCheck?.km <= 0 &&
      'Please make sure that the Billing Information and the Delivery Km Field of the Subscription has been filled');
  const deliveryDateMissing =
    !data?.deliveryDate &&
    'Please make sure that the Delivery Date of the Subscription has been filled';

  data.cannotMakeReturnReason = billingInfoORKmMissing || deliveryDateMissing;

  data.deliveryInfoAddress = {
    lat: data?.deliveryInfo?.address?.latitude,
    lng: data?.deliveryInfo?.address?.longitude,
    latitude: data?.deliveryInfo?.address?.latitude,
    longitude: data?.deliveryInfo?.address?.longitude,
    streetAddress: data?.deliveryInfo?.address?.streetAddress,
    streetNumber: data?.deliveryInfo?.address?.streetNumber,
    region: data?.deliveryInfo?.address?.region,
    postalCode: data?.deliveryInfo?.address?.postalCode,
    country: data?.deliveryInfo?.address?.country,
    locality: data?.deliveryInfo?.address?.locality,
    location: data?.deliveryInfo?.location,
  };
  data.returnInfoAddress = {
    lat: data?.returnInfo?.address?.latitude,
    lng: data?.returnInfo?.address?.longitude,
    latitude: data?.returnInfo?.address?.latitude,
    longitude: data?.returnInfo?.address?.longitude,
    streetAddress: data?.returnInfo?.address?.streetAddress,
    streetNumber: data?.returnInfo?.address?.streetNumber,
    region: data?.returnInfo?.address?.region,
    postalCode: data?.returnInfo?.address?.postalCode,
    country: data?.returnInfo?.address?.country,
    locality: data?.returnInfo?.address?.locality,
    location: data?.returnInfo?.location,
  };
  const deliveryDetails = [];
  const returnDetails = [];
  data?.subscribers?.forEach((subscriber, index) => {
    if (subscriber.activeDriver) {
      deliveryDetails?.push({
        title: `driver${index + 1}`,
        isSelected: subscriber?.id === data?.deliveryInfo?.userId,
        fields: [
          {
            label: 'name',
            value: `${subscriber?.firstName} ${subscriber?.lastName}`,
          },
          {
            label: 'dob',
            value: subscriber?.dateOfBirth
              ? formatDate(subscriber?.dateOfBirth, 'DD.MM.YYYY')
              : '-',
          },
          {
            label: 'drivingLicense',
            value: subscriber?.driversLicenseNumber || '-',
          },
          {
            label: 'dateOfIssue',
            value: subscriber?.driversLicenseIssueDate
              ? formatDate(subscriber?.driversLicenseIssueDate, 'DD.MM.YYYY')
              : '-',
          },
        ],
      });
      returnDetails?.push({
        title: `driver${index + 1}`,
        isSelected: subscriber?.id === data?.returnInfo?.userId,
        fields: [
          {
            label: 'name',
            value: `${subscriber?.firstName} ${subscriber?.lastName}`,
          },
          {
            label: 'dob',
            value: subscriber?.dateOfBirth
              ? formatDate(subscriber?.dateOfBirth, 'DD.MM.YYYY')
              : '-',
          },
          {
            label: 'drivingLicense',
            value: subscriber?.driversLicenseNumber || '-',
          },
          {
            label: 'dateOfIssue',
            value: subscriber?.driversLicenseIssueDate
              ? formatDate(subscriber?.driversLicenseIssueDate, 'DD.MM.YYYY')
              : '-',
          },
        ],
      });
    }
  });
  deliveryDetails?.push({
    title: 'lessee',
    isSelected: false,
    fields: [
      {
        label: 'name',
        value: data?.billingInfo?.firstName
          ? `${data?.billingInfo?.firstName} ${data?.billingInfo?.lastName}`
          : `${data?.billingInfo?.companyName}`,
      },
      {
        label: 'activity',
        value: data?.billingInfo?.activity || '-',
      },
      {
        label: 'address',
        value: data?.billingInfo?.address || '-',
      },
      {
        label: 'vat',
        value: data?.billingInfo?.vat || '-',
      },
      {
        label: 'doi',
        value: data?.billingInfo?.doi || '-',
      },
    ],
  });
  returnDetails?.push({
    title: 'lessee',
    isSelected: false,
    fields: [
      {
        label: 'name',
        value: data?.billingInfo?.firstName
          ? `${data?.billingInfo?.firstName} ${data?.billingInfo?.lastName}`
          : `${data?.billingInfo?.companyName}`,
      },
      {
        label: 'activity',
        value: data?.billingInfo?.activity || '-',
      },
      {
        label: 'address',
        value: data?.billingInfo?.address || '-',
      },
      {
        label: 'vat',
        value: data?.billingInfo?.vat || '-',
      },
      {
        label: 'doi',
        value: data?.billingInfo?.doi || '-',
      },
    ],
  });
  deliveryDetails?.push({
    title: 'vehicleDetails',
    isSelected: false,
    fields: [
      {
        label: 'plate',
        value: data?.vehicle?.plate || '-',
      },
      {
        label: 'brandModel',
        value: `${data?.vehicle?.manufacturer || '-'} ${data?.vehicle?.model}`,
      },
      {
        label: 'color',
        value: `${data?.vehicle?.properties?.color || '-'}`,
      },
    ],
  });
  returnDetails?.push({
    title: 'vehicleDetails',
    isSelected: false,
    fields: [
      {
        label: 'plate',
        value: data?.vehicle?.plate || '-',
      },
      {
        label: 'brandModel',
        value: `${data?.vehicle?.manufacturer || '-'} ${data?.vehicle?.model}`,
      },
      {
        label: 'color',
        value: `${data?.vehicle?.properties?.color || '-'}`,
      },
    ],
  });
  deliveryDetails?.push({
    title: 'km',
    isSelected: false,
    fields: [
      {
        label: 'delivery',
        value: data?.deliveryInfo?.kmFuelTiresCheck?.km || '-',
      },
    ],
  });
  returnDetails?.push({
    title: 'km',
    isSelected: false,
    fields: [
      {
        label: 'delivery',
        value: data?.returnInfo?.kmFuelTiresCheck?.km || '-',
      },
    ],
  });
  deliveryDetails?.push({
    title: 'fuel',
    isSelected: false,
    fields: [
      {
        label: 'delivery',
        value: data?.deliveryInfo?.kmFuelTiresCheck?.fuel,
      },
    ],
  });
  returnDetails?.push({
    title: 'fuel',
    isSelected: false,
    fields: [
      {
        label: 'delivery',
        value: data?.returnInfo?.kmFuelTiresCheck?.fuel,
      },
    ],
  });
  data?.deliveryInfo?.kmFuelTiresCheck?.tires?.forEach((tire) => {
    deliveryDetails?.push({
      title: 'tires',
      isSelected: false,
      fields: [
        {
          label: 'Position',
          value: tire?.position || '-',
        },
        {
          label: 'dimensions',
          value: tire?.dimensions || '-',
        },
        {
          label: 'DOT',
          value: tire?.dot || '-',
        },
      ],
    });
  });

  data?.returnInfo?.kmFuelTiresCheck?.tires?.forEach((tire) => {
    returnDetails?.push({
      title: 'tires',
      isSelected: false,
      fields: [
        {
          label: 'Position',
          value: tire?.position || '-',
        },
        {
          label: 'dimensions',
          value: tire?.dimensions || '-',
        },
        {
          label: 'DOT',
          value: tire?.dot || '-',
        },
      ],
    });
  });

  data.deliveryInfoDriversDetails = deliveryDetails;
  data.returnInfoDriversDetails = returnDetails;
  // eslint-disable-next-line no-use-before-define
  data.linkedSubscriptions = handleSubscriptions(linkedSubscriptions);
  data.products = data.products?.map((item) => {
    return {
      ...item,
      vat: item?.vat ? item.vat * 100 : item?.vat,
      customFields: {
        ...item?.customFields,
        initialMonthsLength: item?.customFields?.months?.length,
        months: item?.customFields?.months
          ? item?.customFields?.months?.map((month) => {
              return {
                text: `${month}ος`,
                value: month,
              };
            })
          : undefined,
        extraMonths: item?.customFields?.extraMonths
          ? item?.customFields?.extraMonths?.map((month) => {
              return {
                text: `${month}ος`,
                value: month,
              };
            })
          : undefined,
      },
    };
  });
  return data;
};
