import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { agentsServices, serviceVehiclesServices } from '@services';
import { getAgentsRequestPayload } from 'hooks/agents/utils';
import { handleAgentsData, handleServiceVehicleData } from './utils';

export const useServiceVehicleOperations = () => {
  const router = useRouter();
  const serviceVehicleId = router.query?.serviceVehicleId;

  const serviceVehicleResponse = useQuery(
    [QUERY_KEYS.GET_SERVICE_VEHICLE_KEY, serviceVehicleId],
    () => serviceVehiclesServices.getServiceVehicle(serviceVehicleId),
    {
      enabled: !!serviceVehicleId,
    }
  );

  const serviceVehiclesTypesResponse = useQuery(
    QUERY_KEYS.GET_SERVICE_VEHICLES_TYPES_KEY,
    () => serviceVehiclesServices?.getServiceVehiclesTypes(),
    {
      enabled: !!serviceVehicleId,
    }
  );

  const serviceVehicle = handleServiceVehicleData(
    serviceVehicleResponse?.data?.data,
    serviceVehiclesTypesResponse?.data?.data
  );

  const agentsResponse = useQuery(
    QUERY_KEYS.GET_AGENTS_KEY,
    () =>
      agentsServices.getFilterAgents({
        params: {
          page: 1,
          pageSize: 1000,
        },
        payload: getAgentsRequestPayload({}),
      }),
    {
      enabled: !!serviceVehicleId,
    }
  );

  const agents = handleAgentsData(agentsResponse?.data?.data);

  useEffect(() => {
    if (serviceVehicleResponse?.isError) {
      showError(serviceVehicleResponse.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceVehicleResponse?.isError]);

  useEffect(() => {
    if (agentsResponse?.isError) {
      showError(agentsResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentsResponse?.isError]);

  useEffect(() => {
    if (serviceVehiclesTypesResponse?.isError) {
      showError(serviceVehiclesTypesResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceVehiclesTypesResponse?.isError]);

  return {
    loading: serviceVehicleResponse?.isFetching || agentsResponse?.isFetching,
    serviceVehicle,
    agents,
    getServiceVehicle: serviceVehicleResponse?.refetch,
  };
};
