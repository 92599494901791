import { formatDate, groupItems } from '@utils';

export const handleHandoveredCars = (array, groupType) => {
  if (!array) {
    return [];
  }
  array?.forEach((item, index, newArray) => {
    let subscriberName = '';
    if (item?.plate) {
      newArray[index] = item;
      return;
    }
    item?.subscribers?.forEach((sub) => {
      sub?.subscriptions?.forEach((subsciption) => {
        if (subsciption?.role === 1) {
          subscriberName = sub?.firstName
            ? `${sub?.firstName} ${sub?.lastName}`
            : '-';
        }
      });
    });

    const row = {
      plate: item?.vehicle?.plate || '-',
      subscriber: subscriberName,
      image: item?.vehicle?.images?.[0]?.url,
      deliveryDate: item.deliveryDate
        ? formatDate(item.deliveryDate, 'DD.MM.YYYY')
        : '-',
      deliveryTime: item.deliveryDate
        ? formatDate(item.deliveryDate, 'HH:mm')
        : '-',
      rawDeliveryDate: item.deliveryDate,
      hasDeliveryTenancyAgreement: !!item?.deliveryInfo?.tenancyAgreement,
      returnDate: item.returnDate
        ? formatDate(item.returnDate, 'DD.MM.YYYY')
        : '-',
      returnTime: item.returnDate ? formatDate(item.returnDate, 'HH:mm') : '-',
      rawReturnDate: item.returnDate,
      hasReturnTenancyAgreement: !!item?.returnInfo?.tenancyAgreement,

      id: item.id,
      subscriptionType: item.type, // SUBSCRIPTIONS_TYPES[item.type],
    };
    newArray[index] = row;

    return newArray;
  });

  const groupTypeByDate = groupItems(array, groupType, 'desc');
  return groupTypeByDate;
};
