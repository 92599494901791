import { useCallback, useState } from 'react';
import { googlePlacesServices } from '@services';
import { showError } from '@utils';
import { debounce } from 'lodash';
import normalizeGoogleAddress from 'utils/normalizeGoogleAddress';

export const useSearchAddress = () => {
  const [options, setOptions] = useState([]);
  const [address, setAddress] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchGooglePlaces = useCallback(
    debounce(async (payload) => {
      try {
        const places = await googlePlacesServices.getGooglePlaces(payload);
        setOptions(places?.data);
      } catch (error) {
        showError(error);
      }
    }, 350),
    [debounce, googlePlacesServices]
  );

  const getGeocodeGooglePlace = async (payload) => {
    try {
      const { data } = await googlePlacesServices.geocodeGooglePlace(payload);
      const tempAddress = normalizeGoogleAddress(data);
      setAddress(tempAddress);
    } catch (error) {
      showError(error);
    }
  };

  const handleClearAddress = () => {
    setOptions([]);
    setAddress(null);
  };

  return {
    options,
    address,
    fetchGooglePlaces,
    getGeocodeGooglePlace,
    handleClearAddress,
  };
};
