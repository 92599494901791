import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
} from '@services';
import {
  handleArnmAccidentsTaskData,
  handleAssigneeData,
  handleVehicleData,
} from './utils';

export const useArnmAccidentsTaskOperations = () => {
  const router = useRouter();
  const arnmAccidentsTaskId = router.query?.arnmAccidentsTaskId;

  const arnmAccidentsTaskResponse = useQuery(
    [QUERY_KEYS.GET_ARNM_ACCIDENTS_TASK_KEY, arnmAccidentsTaskId],
    () => boardsServices.getTaskById(arnmAccidentsTaskId),
    {
      enabled: !!arnmAccidentsTaskId,
    }
  );

  const arnmAccidentsTask = handleArnmAccidentsTaskData(
    arnmAccidentsTaskResponse?.data?.data
  );

  const carId = arnmAccidentsTaskResponse?.data?.data?.customFields?.carId;

  const vehicleResponse = useQuery(
    [QUERY_KEYS.GET_VEHICLE_BY_INTERNAL_ID_KEY, carId],
    () => fleetVehiclesServices.getVehicleByInternalId(carId),
    {
      enabled: arnmAccidentsTaskResponse.isFetched && !!carId,
    }
  );

  const vehicle = handleVehicleData(vehicleResponse?.data?.data);

  const assigneesResponse = useQuery(
    QUERY_KEYS.GET_ARNM_ACCIDENTS_ASSIGNEES_KEY,
    () =>
      agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      })
  );

  const assignees = handleAssigneeData(assigneesResponse?.data?.data);

  const updateTask = async (payload) => {
    try {
      if (payload?.cardId) {
        const id = payload?.cardId;
        delete payload?.cardId;
        await boardsServices.updateTask(id, payload);
      } else {
        await boardsServices.updateTask(payload?.id, payload);
      }
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (arnmAccidentsTaskResponse?.isError) {
      showError(arnmAccidentsTaskResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arnmAccidentsTaskResponse?.isError]);

  useEffect(() => {
    if (vehicleResponse?.isError) {
      showError(vehicleResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleResponse?.isError]);

  useEffect(() => {
    if (assigneesResponse?.isError) {
      showError(assigneesResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneesResponse?.isError]);

  const loading =
    arnmAccidentsTaskResponse?.isFetching ||
    vehicleResponse?.isFetching ||
    assigneesResponse?.isFetching;

  return {
    loading,
    task: arnmAccidentsTask,
    taskId: arnmAccidentsTaskId,
    getArnmAccidentsTask: arnmAccidentsTaskResponse.refetch,
    vehicle,
    assignees,
    loadingAssignees: assigneesResponse?.isFetching,
    updateTask,
  };
};
