import { combineReducers } from '@reduxjs/toolkit';

import { oauthSlice } from './oauth/oauthSlice';
import { vehiclesSlice } from './catalog/vehiclesSlice';
import { subscriptionsSlice } from './subscriptions/subscriptionsSlice';
import { usersSlice } from './users/usersSlice';
import { bookingsSlice } from './bookings/bookingsSlice';
import { fleetVehiclesSlice } from './fleetVehicles/fleetVehiclesSlice';
import { fleetVehicleSlice } from './fleetVehicles/fleetVehicleSlice';
import { returnsInboxSlice } from './returnsInbox/returnsInboxSlice';
import { notificationsSlice } from './notifications/notificationsSlice';
import { handoverCarsSlice } from './handoverCars/handoverCarsSlice';
import { buyOutSlice } from './buyout/buyOutSlice';
import { layoutSlice } from './layout/layoutSlice';
import { arnmAccidentsSlice } from './arnmAccidents/arnmAccidentsSlice';
import { arnmRepairsSlice } from './arnmRepairs/arnmRepairsSlice';
import { arnmMaintenanceSlice } from './arnmMaintenance/arnmMaintenanceSlice';
import { ebikesSlice } from './ebike/ebikeSlice';
import { vendorsSlice } from './vendors/vendorsSlice';
import { addFleetVehicleSlice } from './fleetVehicles/addFleetVehicleSlice';
import { availabilityTableSlice } from './availabilityTable/availabilityTableSlice';
import { reservationsSlice } from './reservations/reservationsSlice';
import { inspectionCarsSlice } from './inspectionCars/inspectionCarsSlice';
import { sellVehiclesSlice } from './sellVehicles/sellVehiclesSlice';
import { sellRequestsSlice } from './sellRequests/sellRequestsSlice';
import { communicationSlice } from './communication/communicationSlice';
import { defleetSlice } from './defleet/defleetSlice';

/* ui grid setting */
import { uiSettingsSlice } from './uiSettings/uiSettingsSlice';
import { mailBoxesSlice } from './mailBoxes/mailBoxesSlice';
import { driverseCampaignsSlice } from './driverseCampaigns/driverseCampaignsSlice';
import { driversePartnersSlice } from './driversePartners/driversePartnersSlice';
import { productsSlice } from './products/productsSlice';

const rootReducer = combineReducers({
  [oauthSlice.name]: oauthSlice.reducer,
  [vehiclesSlice.name]: vehiclesSlice.reducer,
  [subscriptionsSlice.name]: subscriptionsSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [bookingsSlice.name]: bookingsSlice.reducer,
  [fleetVehiclesSlice.name]: fleetVehiclesSlice.reducer,
  [fleetVehicleSlice.name]: fleetVehicleSlice.reducer,
  [returnsInboxSlice.name]: returnsInboxSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [handoverCarsSlice.name]: handoverCarsSlice.reducer,
  [buyOutSlice.name]: buyOutSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
  [arnmAccidentsSlice.name]: arnmAccidentsSlice.reducer,
  [arnmRepairsSlice.name]: arnmRepairsSlice.reducer,
  [arnmMaintenanceSlice.name]: arnmMaintenanceSlice.reducer,
  [ebikesSlice.name]: ebikesSlice.reducer,
  [vendorsSlice.name]: vendorsSlice.reducer,
  [uiSettingsSlice.name]: uiSettingsSlice.reducer,
  [addFleetVehicleSlice.name]: addFleetVehicleSlice.reducer,
  [availabilityTableSlice.name]: availabilityTableSlice.reducer,
  [reservationsSlice.name]: reservationsSlice.reducer,
  [inspectionCarsSlice.name]: inspectionCarsSlice.reducer,
  [sellVehiclesSlice.name]: sellVehiclesSlice.reducer,
  [sellRequestsSlice.name]: sellRequestsSlice.reducer,
  [communicationSlice.name]: communicationSlice.reducer,
  [mailBoxesSlice.name]: mailBoxesSlice.reducer,
  [driverseCampaignsSlice.name]: driverseCampaignsSlice.reducer,
  [driversePartnersSlice.name]: driversePartnersSlice.reducer,
  [defleetSlice.name]: defleetSlice.reducer,
  [productsSlice.name]: productsSlice.reducer,
});

export default rootReducer;
