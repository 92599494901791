import dynamic from 'next/dynamic';

const AvailabilityTableGrid = dynamic(() =>
  import('./AvailabilityTableGrid/AvailabilityTableGrid')
);
const AvailabilityTableFilters = dynamic(() =>
  import('./AvailabilityTableFilters/AvailabilityTableFilters')
);
const AvailablityExportUTRModal = dynamic(() =>
  import('./AvailablityExportUTRModal/AvailablityExportUTRModal')
);
export {
  AvailabilityTableGrid,
  AvailabilityTableFilters,
  AvailablityExportUTRModal,
};
