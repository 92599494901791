import dynamic from 'next/dynamic';

const GreenCardGrid = dynamic(() => import('./GreenCardGrid/GreenCardGrid'));

const AddGreenCardModal = dynamic(() =>
  import('./AddGreenCardModal/AddGreenCardModal')
);

const AddGreenCardForm = dynamic(() =>
  import('./AddGreenCardForm/AddGreenCardForm')
);

const GreenCardVehicleHeader = dynamic(() =>
  import('./GreenCardVehicleHeader/GreenCardVehicleHeader')
);

const GreenCardForm = dynamic(() => import('./GreenCardForm/GreenCardForm'));

const UploadGreenCardDocument = dynamic(() =>
  import('./UploadGreenCardDocument/UploadGreenCardDocument')
);

const GreenCardListContent = dynamic(() =>
  import('./GreenCardListContent/GreenCardListContent')
);

export {
  GreenCardVehicleHeader,
  GreenCardForm,
  UploadGreenCardDocument,
  GreenCardGrid,
  AddGreenCardModal,
  AddGreenCardForm,
  GreenCardListContent,
};
