import dynamic from 'next/dynamic';

const TrafficOffensesGrid = dynamic(() =>
  import('./TrafficOffensesGrid/TrafficOffensesGrid')
);
const TrafficOffensesAddNewModal = dynamic(() =>
  import('./TrafficOffensesAddNewModal/TrafficOffensesAddNewModal')
);
const TrafficOffensesVehicleHeader = dynamic(() =>
  import('./TrafficOffensesVehicleHeader/TrafficOffensesVehicleHeader')
);
const TrafficOffensesForm = dynamic(() =>
  import('./TrafficOffensesForm/TrafficOffensesForm')
);
const TrafficOffensesDocumentModal = dynamic(() =>
  import('./TrafficOffensesDocumentModal/TrafficOffensesDocumentModal')
);

const TrafficOffensesListContent = dynamic(() =>
  import('./TrafficOffensesListContent/TrafficOffensesListContent')
);

export {
  TrafficOffensesGrid,
  TrafficOffensesAddNewModal,
  TrafficOffensesVehicleHeader,
  TrafficOffensesForm,
  TrafficOffensesDocumentModal,
  TrafficOffensesListContent,
};
