import { AVAILABILITY_STATUSES, RESERVATIONS_TYPES } from '@constants';
import { formatDate, formatVehicleStage } from '@utils';

const normalizeConstantsToMap = (data) =>
  Object.keys(data).reduce((acc, cur) => {
    acc = {
      ...acc,
      [data[cur].value]: data[cur].text,
    };

    return acc;
  }, {});

const getReservationType = (item, reservationTypes) => {
  if (
    item.reservationType >= RESERVATIONS_TYPES.free.value &&
    reservationTypes[item.reservationType]
  ) {
    return reservationTypes[item.reservationType];
  }

  return '-';
};

export const handleAvailability = (data = []) => {
  if (!data || !data.length) {
    return [];
  }
  const statuses = normalizeConstantsToMap(AVAILABILITY_STATUSES);
  const reservationTypes = normalizeConstantsToMap(RESERVATIONS_TYPES);

  return data.map((item) => ({
    ...item,
    skuId: item.skuId || '-',
    plate: item.plate || '-',
    brand: item.brand || '-',
    model: item.model || '-',
    edition: item.edition || '-',
    fuelType: item.fuelType || '-',
    price: item.price || '-',
    erfudd: item.erfudd ? formatDate(item.erfudd) : '-',
    id: item.vehicleId,
    procurementStageId: formatVehicleStage(item.procurementStageId),
    status:
      item.status >= AVAILABILITY_STATUSES.onlease.value
        ? statuses[item.status]
        : '-',
    reservationType: getReservationType(item, reservationTypes),
    // item.reservationType >= RESERVATIONS_TYPES.free.value
    //   ? reservationTypes[item.reservationType]
    //   : '-',
  }));
};

const getServicePointsOptions = (data) =>
  data
    ?.map((item) => ({
      text: item.name,
      value: item.id,
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));

const getProcurementStagesOptions = () => {
  const fields = {
    0: 'Created',
    1: 'Order',
    2: 'Customs',
    3: 'Payment',
    4: 'Dealer',
    5: 'Fleet',
    6: 'Defleeted',
    7: 'Cancelled',
  };

  return Object?.keys(fields)?.map?.((key) => {
    return {
      text: fields[key],
      value: Number(key),
    };
  });
};

export const handleEnums = (data = {}) => {
  const defaultItem = {
    value: null,
    text: '-',
  };

  const defaultLocations = [
    { text: 'instacar Pigasou', value: 'instacar-pigasou' },
    // { text: 'New Way', value: 'newway' },
    // { text: 'Zotalis', value: 'zotalis' },
    { text: 'Kineo', value: 'kineo' },
    { text: 'instacar Employee', value: 'instacar-employee' },
    { text: 'Thessaloniki Cars Airport', value: 'thessaloniki-cars-airport' },
    { text: 'Seat Velmar Gerakas', value: 'seat-velmar-gerakas' },
    { text: 'Ford Velmar Marousi', value: 'ford-velmar-marousi' },
    {
      text: 'Seat Diamantoglou Halkidiki',
      value: 'seat-diamantoglou-halkidiki',
    },
    { text: 'Mermigas', value: 'mermigas' },
    { text: 'mazda metamorfosi', value: 'mazda-metamorfosi' },
    { text: 'Liapatis Dafni', value: 'liapatis-dafni' },
    { text: 'Koutsalaras Patra', value: 'koutsalaras-patra' },
    { text: 'Kostoglou Konstantinos', value: 'kostoglou-konstantinos' },
    { text: 'Autoone Service', value: 'autoone-service' },
    { text: 'Autofix Mykonos', value: 'autofix-mykonos' },
    { text: 'instacar HQ', value: 'instacar-hq' },
    { text: 'instacar Άνοιξη', value: 'instacar-anoixh' },
    { text: 'instacar Αργυρούπολη', value: 'instacar-argyroupoli' },
  ];

  const statusesOptions = data?.statuses?.map((item) => ({
    value: item.id,
    text: AVAILABILITY_STATUSES?.[item?.name]?.text,
  }));
  const skuTypes = data?.skuTypes?.map((item) => ({
    value: item.name,
    text: item.name,
  }));

  const reservationsTypesOptions = data?.reservationsTypes?.map((item) => ({
    value: item.id,
    text: RESERVATIONS_TYPES?.[item.name]?.text,
  }));
  const bodyTypesOptions = data?.bodyTypes?.map((item) => ({
    value: item.name,
    text: item.name,
  }));

  const colors = Object.keys(data?.colors)?.map((item) => ({
    value: data?.colors[item].name,
    text: data?.colors[item].name,
  }));

  const transmissions = data?.transmissions?.map((item) => ({
    value: item.name,
    text: item.name,
  }));

  const fuelTypes = data?.fuelTypes?.map((item) => ({
    value: item.name,
    text: item.name,
  }));

  const engineTypes = data?.engineTypes?.map((item) => ({
    value: item.name,
    text: item.name,
  }));

  const brandOptions = Object.keys(data?.models)?.map((key) => ({
    value: data.models[key].name,
    text: data.models[key].name,
    models: Object.keys(data.models[key].models).map((modelKey) => ({
      value: data.models[key].models[modelKey].name,
      text: `${data.models[key].models[modelKey].name} (${data.models[key].name})`,
    })),
  }));

  const servicePoints = getServicePointsOptions(data.servicePoints);

  const procurementStages = getProcurementStagesOptions();

  return {
    statuses: statusesOptions, // [defaultItem, ...statusesOptions],
    reservationsTypes: [
      { value: -1, text: 'Free' },
      ...reservationsTypesOptions,
    ],
    bodyTypes: bodyTypesOptions,
    brands: brandOptions,
    servicePoints: [defaultItem, ...defaultLocations, ...servicePoints],
    skuTypes,
    colors,
    transmissions,
    fuelTypes,
    engineTypes,
    procurementStages,
  };
};
