import dynamic from 'next/dynamic';

const InspectionVehicleDetails = dynamic(() =>
  import('./InspectionVehicleDetails/InspectionVehicleDetails')
);
const InspectionOverview = dynamic(() =>
  import('./InspectionOverview/InspectionOverview')
);
const InspectionLastStep = dynamic(() =>
  import('./InspectionLastStep/InspectionLastStep')
);
const InspectionFilters = dynamic(() =>
  import('./InspectionFilters/InspectionFilters')
);
const InspectionStep = dynamic(() => import('./InspectionStep/InspectionStep'));

export {
  InspectionVehicleDetails,
  InspectionOverview,
  InspectionLastStep,
  InspectionFilters,
  InspectionStep,
};
