import { groupBy, omit, pick, pickBy } from 'lodash';
import { FILTER_OPERATOR_ENUMS, FILTER_RULE_ENUMS } from '@constants';
import moment from 'moment';
import { formatDate } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';
import { SELL_REQUESTS_PAGE_SIZE } from './constants';

export const getCleanedUrlParams = (params) =>
  pickBy(params, (item) => item !== null && item !== undefined && item !== '');

export const getUrl = (params) => {
  const urlParams = getCleanedUrlParams(params);
  const searchParams = new URLSearchParams(urlParams).toString();

  return `${URLS.sellRequests}?${searchParams}`;
};
export const getSellRequestsQueryParams = (payload = {}) => {
  const customFields = [];
  if (!payload?.pageSize) {
    payload.pageSize = SELL_REQUESTS_PAGE_SIZE;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  if (!payload.order) {
    payload.order = 'desc';
    payload.orderBy = 'createdAt';
  }

  Object.keys(payload)?.forEach((key) => {
    if (customFields.includes(key)) {
      payload[`customFields.${key}`] = payload[key];
      delete payload[key];
    }
  });

  return getCleanedUrlParams(
    pick(payload, [
      'page',
      'pageSize',
      'order',
      'orderBy',
      'boardType',
      'searchTerm',
      'status',
    ])
  );
};

export const getSellRequestsPayload = (payload = {}) => {
  const fields =
    omit(payload, [
      'page',
      'pageSize',
      'order',
      'orderBy',
      'boardType',
      'searchTerm',
      'filters',
    ]) || {};
  const { searchTerm, filters } = payload;

  const value = [
    // to be enabled in the future
    // {
    //   field: 'archived_at',
    //   operator: 0,
    //   type: FILTER_RULE_ENUMS.isEmpty,
    // },
    ...Object.keys(fields).map((field) => {
      if (field === 'status') {
        return {
          field,
          operator: FILTER_OPERATOR_ENUMS['='],
          type: FILTER_RULE_ENUMS.IsAnyOfRuleType,
          value: payload[field]?.value || payload[field],
        };
      }
      return {
        field,
        operator: FILTER_OPERATOR_ENUMS['='],
        type: payload[field]?.type || FILTER_RULE_ENUMS.ComparableRuleType,
        value: payload[field]?.value || payload[field],
      };
    }),
    ...filters,
  ];

  if (searchTerm) {
    const filedsToSearch = [
      'id',
      'customFields.vehiclePlate',
      'customFields.loanContractId',
    ];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: filedsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field: `${field}`,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  return {
    boardType: payload.boardType,
    type: FILTER_RULE_ENUMS.AndRuleType,
    field: null,
    operator: null,
    value,
  };
};

const computeOfferRequestColor = (offerRequestDate) => {
  if (offerRequestDate) {
    const given = moment(offerRequestDate, 'YYYY-MM-DD');
    const current = moment().startOf('day');
    const dayDiff = moment.duration(current.diff(given)).asDays();
    if (dayDiff >= 35) {
      return '#FFA366';
    }
    return '#03AA46';
  }
  if (!offerRequestDate) {
    return '#FF0000';
  }
};

export const normaliseSellRequestsTasks = (data = [], assignees = []) => {
  if (!data.length > 0) {
    return {};
  }
  let totalFinalPrice = 0;

  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0]}${cur.lastName?.[0]}`.toUpperCase(),
    };
    return acc;
  }, {});

  const tasks = data.map((item) => {
    const assignee = assigneesMap[item.assignee] || {};
    if (item?.customFields?.finalPrice?.number) {
      totalFinalPrice += Number(item?.customFields?.finalPrice?.number);
    }
    return {
      ...item,
      href: `${URLS.sellRequests}/${item?.id}`,
      status: item.status,
      assignee,
      description: item?.name,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      date: item?.createdAt ? formatDate(item?.createdAt, 'DD/MM/YYYY') : '-',
      userId: item?.customFields?.userId,
      customFields: {
        ...item.customFields,
        finalPrice: item?.customFields?.finalPrice?.number
          ? new Intl.NumberFormat('el-GR', {
              style: 'currency',
              currency: 'EUR',
            }).format(item?.customFields?.finalPrice?.number)
          : '-',
        offerSentDate: item?.customFields?.offerSentDate
          ? formatDate(item?.customFields?.offerSentDate, 'DD/MM/YYYY')
          : '-',
        offerRequestDateColor: computeOfferRequestColor(
          item?.customFields?.offerRequestDate
        ),
        approvalStatus: item?.customFields?.approvalStatus || '-',
        loan: item?.customFields?.loan,
        loanContractId: item?.customFields?.loanContractId || '-',
        vehiclePlate: item?.customFields?.vehiclePlate || '-',
        appointmentDate: item?.customFields?.appointmentDate
          ? formatDate(item?.customFields?.appointmentDate, 'YYYY/MM/DD HH:mm')
          : '-',
        billedAt: item?.customFields?.billedAt
          ? formatDate(item?.customFields?.billedAt, 'DD/MM/YYYY')
          : '-',
      },
      name: item?.name || '',
    };
  });

  return {
    tasks,
    totalFinalPrice: new Intl.NumberFormat('el-GR', {
      style: 'currency',
      currency: 'EUR',
    }).format(totalFinalPrice),
  };
};

export const getColumns = (tasks = [], board = {}) => {
  const order = board.statusOrder;
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: board?.statuses?.[cur]?.label || cur,
      cards: groupedByStatus[cur] || [],
      styles: board?.statuses?.[cur]?.styles,
      value: true,
      label: board?.statuses?.[cur]?.label || cur,
      key: cur,
    });

    return acc;
  }, []);

  return columns;
};
