import { formatDate } from '@utils';

export const handleInsurancesData = (data = []) => {
  if (!data) {
    return [];
  }
  return data.map((item) => {
    const row = {
      email: item?.email || '-',
      internalId: item?.internalId || '-',
      name: item?.name || '-',
      telephoneNumber: item?.telephoneNumber || '-',
      id: item?.id,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY')
        : '-',
    };

    return row;
  });
};

export const handleInsuranceData = (item) => {
  if (!item) {
    return {};
  }
  const insurance = {
    email: item?.email || '-',
    internalId: item?.internalId || '-',
    name: item?.name || '-',
    phoneNumber: item?.telephoneNumber || '-',
    id: item?.id || '-',
    createdAt: item?.createdAt
      ? formatDate(item?.createdAt, 'DD/MM/YYYY HH:mm')
      : '-',
    updatedAt: item?.updatedAt
      ? formatDate(item?.updatedAt, 'DD/MM/YYYY HH:mm')
      : '-',
  };
  return insurance;
};
