import theme from 'config/theme';

export const ORDERS_STATUS = {
  0: 'Created',
  1: 'Paid',
  2: 'Completed',
  3: 'Canceled',
  4: 'Refunded',
  5: 'Failed',
};

export const TRANSACTIONS_STATUSES = {
  success: {
    value: 0,
    label: 'Success',
    backgroundColor: theme.palette.greens.codingGreen200,
    color: theme.palette.greens.codingGreen900,
  },
  failed: {
    value: 1,
    label: 'Failed',
    backgroundColor: theme.palette.reds.codingRed200,
    color: theme.palette.reds.codingRed900,
  },
  refund: {
    value: 2,
    label: 'Refund',
    backgroundColor: theme.palette.yellows.yellow200,
    color: theme.palette.yellows.yellow900,
  },
};

export const STATUSES_COLORS = {
  0: 'Created',
  1: 'Paid',
  2: 'Completed',
  3: 'Canceled',
  4: 'Refunded',
  5: 'Failed',
};
