import { BOARDS, BOARDS_ID } from '@constants';
import theme from 'config/theme';

export const TRAFFIC_OFFENSES_PAGE_SIZE = 100;
export const SERVICE_POINTS_PAGE_SIZE = 1000;

export const TRAFFIC_OFFENSES_BOARD = {
  label: 'trafficOffenses',
  key: 'trafficOffenses',
  type: BOARDS.TRAFFIC_OFFENSES,
  id: BOARDS_ID.TRAFFIC_OFFENSES,
  statuses: {
    0: {
      label: 'Backlog',
      styles: {
        backgroundColor: theme.palette.grays.gray200,
        color: theme.palette.grays.gray900,
      },
    },
    2: {
      label: 'In Progress',
      styles: {
        backgroundColor: theme.palette.yellows.yellow200,
        color: theme.palette.yellows.yellow900,
      },
    },
    15: {
      label: 'Billing',
      styles: {
        backgroundColor: theme.palette.mints.mint200,
        color: theme.palette.mints.mint900,
      },
    },
    3: {
      label: 'Done',
      styles: {
        backgroundColor: theme.palette.greens.green200,
        color: theme.palette.greens.green900,
      },
    },
    12: {
      label: 'Cancelled',
      styles: {
        backgroundColor: theme.palette.oranges.orange200,
        color: theme.palette.oranges.orange900,
      },
    },
  },
  statusOrder: [0, 2, 15, 3, 12],
};
