import { BOARDS_ID } from '@constants';
import { formatDate, sortArray } from '@utils';
import { GREEN_CARD_TASK_STATUSES_NAMES } from 'components/greenCard/constants';
import { URLS } from 'layouts/DrawerLayout/constants';
import { groupBy, omit } from 'lodash';

export const getGreenCardPayload = (params) => {
  const defaultRule = {
    type: 5,
    field: 'boardId',
    operator: 0,
    value: BOARDS_ID.GREEN_CARD,
  };
  return {
    ...params,
    ...defaultRule,
  };
};

const getControlledParking = (controlledParking) => {
  if (controlledParking === 'yes' || controlledParking === 'true') {
    return 'Yes';
  }
  if (controlledParking === 'no' || controlledParking === 'false') {
    return 'No';
  }
  return '-';
};

export const handleGreenCardData = (data = []) => {
  if (!data.length) {
    return {
      boardId: BOARDS_ID.GREEN_CARD,
      tasks: [],
    };
  }
  const tasks = [];

  data?.forEach((item) => {
    const newTasks = {
      ...item,
      id: item?.id,
      date: item.customFields.endDate
        ? formatDate(item.customFields.endDate, 'DD/MM/YYYY')
        : null,
      href: `${URLS.greenCard}/${item?.id}`,
      requestFor: {
        text: `${item?.customFields?.userFullName} - ${item?.customFields?.vehicleDescription} ${item?.customFields?.vehiclePlate}`,
        value: item?.customFields?.userId,
      },
      description: `${item?.customFields?.userFullName} - ${item?.customFields?.vehicleDescription} ${item?.customFields?.vehiclePlate}`,
      status: item?.status,
      startDate: item?.customFields?.startDate
        ? formatDate(item?.customFields?.startDate)
        : '',
      endDate: item?.customFields?.endDate
        ? formatDate(item?.customFields?.endDate)
        : '',
      country: item?.customFields?.country || '-',
      controlledParking: getControlledParking(
        item?.customFields?.controlledParking
      ),
      rejectedBy: item?.customFields?.rejectedBy || '-',
    };
    tasks.push(newTasks);
  });

  return {
    boardId: BOARDS_ID.GREEN_CARD,
    tasks,
  };
};

export const getGreenCardColumns = (tasks = []) => {
  const order = [0, 22, 23, 2, 25, 26, 3, 27, 12];
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: GREEN_CARD_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: GREEN_CARD_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: GREEN_CARD_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const handleGreenCardTaskData = (data) => {
  if (!data) {
    return {};
  }

  const task = {
    ...data,

    customFields: {
      ...omit(data.customFields, ['comments']),
      country: data?.customFields?.country?.map((c) => ({ text: c, value: c })),
      mixedInsuranceExtensionPrice: {
        ...data?.customFields?.mixedInsuranceExtensionPrice,
        number: data?.customFields?.mixedInsuranceExtensionPrice?.number
          ? Number(data.customFields.mixedInsuranceExtensionPrice.number)
          : null,
      },
      customerCost: {
        ...data?.customFields?.customerCost,
        number: data?.customFields?.customerCost?.number
          ? Number(data.customFields.customerCost.number)
          : null,
      },
    },
    changelog: data?.changelog?.reverse(),
    comments: sortArray(data?.comments, 'createdAt', 'desc')?.map((entry) => {
      return {
        id: entry?.id,
        date: entry?.createdAt
          ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
          : '-',
        time: entry?.createdAt ? formatDate(entry?.createdAt, 'HH:mm') : '-',
        name: entry?.userFullName || entry?.user || '-',
        comment: entry?.comment,
        edit: entry?.editedAt
          ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
          : '',
      };
    }),
    documents: data?.documents || [],
  };
  return task;
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${data?.internalId ? `${data?.internalId} -` : '' || ''} ${
      data?.vin ? `${data?.vin} -` : ''
    } ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${
      data?.properties?.transmission
        ? `${data?.properties?.transmission} -`
        : ''
    } ${data?.properties?.fuelType}`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
  };
  return vehicle;
};
