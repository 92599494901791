import { pick, omit } from 'lodash';
import { FILTER_RULE_ENUMS, FILTER_OPERATOR_ENUMS } from '@constants';
import { SUBSCRIPTIONS_LIMIT } from '@store';

export const getSubscriptionsQueryParams = (payload = {}) => {
  if (!payload?.pageSize) {
    payload.pageSize = SUBSCRIPTIONS_LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  return pick(payload, ['page', 'pageSize', 'order', 'orderBy']);
};

export const getSubscriptionsRequestPayload = (
  payload = {},
  modalFiltersRules = null
) => {
  const fields =
    omit(payload, ['page', 'pageSize', 'order', 'orderBy', 'searchTerm']) || {};
  const multyFilterNumberFields = ['status', 'type'];
  const { searchTerm } = payload;

  const _getValue = ({ field }) => {
    if (multyFilterNumberFields.includes(field)) {
      return payload[field]?.split(',').map((item) => Number(item));
    }
  };
  const _getType = ({ field }) => {
    if (multyFilterNumberFields.includes(field)) {
      return FILTER_RULE_ENUMS.IsAnyOfRuleType;
    }
    return FILTER_RULE_ENUMS.ComparableRuleType;
  };

  const value = [
    ...Object.keys(fields).map((field) => ({
      field,
      operator: FILTER_OPERATOR_ENUMS['='],
      type: _getType({ field }),
      value: _getValue({ field }),
    })),
  ];

  value.push(...(modalFiltersRules?.value || []));

  if (searchTerm) {
    const fieldsToSearch = ['vehicleInternalID', 'vehiclePlate'];

    const searchRule = {
      type: FILTER_RULE_ENUMS.OrRuleType,
      value: fieldsToSearch.map((field) => ({
        type: FILTER_RULE_ENUMS.CompareStringRuleType,
        operator: FILTER_OPERATOR_ENUMS.contains,
        field,
        value: searchTerm,
      })),
    };
    value.push(searchRule);
  }

  if (value.length) {
    return {
      type: FILTER_RULE_ENUMS.AndRuleType,
      field: null,
      operator: null,
      value,
    };
  }

  return {
    type: FILTER_RULE_ENUMS.EmptyRuleType,
  };
};
