import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { subscriptionsServices } from '@services';

export const useSubscriptionHistoryOperations = () => {
  const router = useRouter();
  const subscriptionId = router.query?.subscriptionId;

  const historyResponse = useQuery(
    [QUERY_KEYS.GET_SUBSCRIPTION_HISTORY_KEY, subscriptionId],
    () => subscriptionsServices?.getSubscriptionHistoryById(subscriptionId),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (historyResponse?.isError) {
      showError(historyResponse?.error);
    }
  }, [historyResponse?.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    history: historyResponse?.data?.data,
    getSubscriptionHistory: historyResponse?.refetch,
  };
};
