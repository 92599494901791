import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { servicePointsServices } from '@services';
import { handleServicePointData } from './utils';

export const useServicePointOperations = () => {
  const router = useRouter();
  const servicePointId = router.query?.servicePointId;
  const tab = router.query?.tab;

  const servicePointResponse = useQuery(
    [QUERY_KEYS.GET_SERVICE_POINT_KEY, servicePointId],
    () => servicePointsServices.getServicePoint(servicePointId),
    {
      enabled: !!servicePointId,
    }
  );

  const servicePoint = handleServicePointData(servicePointResponse?.data?.data);

  const servicePointsEnumsResponse = useQuery(
    QUERY_KEYS.GET_SERVICE_POINTS_ENUMS_KEY,
    () => servicePointsServices.getServicePointsEnums()
  );

  const enums = servicePointsEnumsResponse?.data?.data || [];

  useEffect(() => {
    if (servicePointResponse?.isError) {
      showError(servicePointResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicePointResponse?.isError]);

  useEffect(() => {
    if (servicePointsEnumsResponse?.isError) {
      showError(servicePointsEnumsResponse?.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicePointsEnumsResponse?.isError]);

  return {
    servicePoint,
    loading:
      servicePointResponse?.isFetching ||
      servicePointsEnumsResponse?.isFetching,
    getServicePoint: servicePointResponse?.refetch,
    enums,
    tab,
  };
};
