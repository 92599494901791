/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

import { showError } from '@utils';
import { ordersServices } from '@services';
import { QUERY_KEYS } from 'constants/queryKeys';
import { handleOrder, handleTransactions } from './utils';

export const useOrderOperations = () => {
  const {
    query: { orderId },
  } = useRouter();

  const orderResponse = useQuery(
    [QUERY_KEYS.GET_ORDER_KEY, orderId],
    () => ordersServices?.getOrderById(orderId),
    {
      enabled: !!orderId,
    }
  );

  const transactionsResponse = useQuery(
    [QUERY_KEYS.GET_ORDER_TRANSACTIONS_KEY, orderId],
    () => ordersServices?.getTransactionsByOrderId(orderId),
    {
      enabled: !!orderId,
    }
  );

  useEffect(() => {
    if (orderResponse?.error) {
      showError(orderResponse?.error);
    }
  }, [orderResponse?.error]);

  useEffect(() => {
    if (transactionsResponse?.error) {
      showError(transactionsResponse?.error);
    }
  }, [transactionsResponse?.error]);
  const order = handleOrder(orderResponse?.data?.data || {});
  const transactions = handleTransactions(
    transactionsResponse?.data?.data || []
  );

  const handleRefresh = async () => {
    await orderResponse.refetch();
    await transactionsResponse.refetch();
  };

  return {
    loading: orderResponse?.isLoading || transactionsResponse?.isLoading,
    orderId,
    order,
    transactions,
    refresh: handleRefresh,
  };
};
