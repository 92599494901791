import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { oauthServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  isLoggedIn: false,
};
// Actual Slice
export const oauthSlice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {
    makeLogin: (state) => {
      state.loading = true;
    },
    makeLoginSuccess: (state) => {
      state.isLoggedIn = true;
    },
    setLogin: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    makeLoginFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },

    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  makeLogin,
  makeLoginSuccess,
  makeLoginFailure,
  setUser,
  setLogin,
} = oauthSlice.actions;

export const selectLoginState = (state) => state.oauth;

export const selectLoggedInUserState = (state) => {
  if (!state?.oauth) {
    return {};
  }

  return state?.oauth?.user || {};
};

export const setIsLoggedIn = (payload) => {
  return (dispatch) => {
    dispatch(setLogin(payload));
  };
};

export const login = (code) => {
  return async (dispatch) => {
    dispatch(makeLogin());

    try {
      const response = await oauthServices?.login(code);
      localStorage.token = `Bearer ${response?.data?.token}`;

      dispatch(makeLoginSuccess(response));
    } catch (error) {
      dispatch(makeLoginFailure(error));
    }
  };
};

export const fetchUserDetail = () => {
  return async (dispatch) => {
    try {
      const user = await oauthServices?.getUser();

      localStorage.user = JSON.stringify(user?.data);
      dispatch(setUser(user?.data));
    } catch (error) {
      dispatch(makeLoginFailure(error));
    }
  };
};

export default oauthSlice.reducer;
