import { BOARDS_ID } from '@constants';
import {
  formatAmount,
  formatDate,
  formatVehicleStage,
  // findNameColor,
  generateShortId,
} from '@utils';

export const handleVehicle = (vehicle, servicePointsAll) => {
  if (!vehicle) {
    return {};
  }

  const _getServicePointsOptions = () => {
    const defaultLocations = [
      { text: 'instacar Pigasou', value: 'pigasou' },
      { text: 'New Way', value: 'new-way' },
      { text: 'Zotalis', value: 'zotalis' },
      { text: 'Kineo', value: 'kineo' },
      { text: 'instacar Employee', value: 'instacar-employee' },
      { text: 'Thessaloniki Cars Airport', value: 'thessaloniki-cars-airport' },
      { text: 'Seat Velmar Gerakas', value: 'seat-velmar-gerakas' },
      { text: 'Ford Velmar Marousi', value: 'ford-velmar-marousi' },
      {
        text: 'Seat Diamantoglou Halkidiki',
        value: 'seat-diamantoglou-halkidiki',
      },
      { text: 'Mermigas', value: 'mermigas' },
      { text: 'mazda metamorfosi', value: 'mazda-metamorfosi' },
      { text: 'Liapatis Dafni', value: 'liapatis-dafni' },
      { text: 'Koutsalaras Patra', value: 'koutsalaras-patra' },
      { text: 'Kostoglou Konstantinos', value: 'kostoglou-konstantinos' },
      { text: 'Autoone Service', value: 'autoone-service' },
      { text: 'Autofix Mykonos', value: 'autofix-mykonos' },
      // TODO: maybe need to be removed
      { text: 'instacar HQ', value: 'instacar-hq' },
      { text: 'instacar Άνοιξη', value: 'instacar-anoixh' },
      { text: 'instacar Αργυρούπολη', value: 'instacar-argyroupoli' },
    ];
    const formatedPoints = servicePointsAll
      ?.map((item) => ({
        text: item.name,
        value: item.id,
      }))
      ?.sort((a, b) => a.text.localeCompare(b.text));

    return [...defaultLocations, ...formatedPoints];
  };

  const findDocument = (documents, id) => {
    return documents?.find((item) => {
      return item?.id === id;
    });
  };

  const data = {
    id: vehicle?.id,
    renderId: generateShortId(),
    images: vehicle?.images || [],
    title: `${vehicle?.manufacturer || ''}${
      vehicle?.model ? ` - ${vehicle?.model}` : ''
    }`,
    internalId: vehicle.internalId || '-',
    plate: vehicle?.plate || '',
    initialPrice: vehicle?.sku?.initialPrice ?? '',
    monthlyPrice: vehicle?.sku?.price ?? '',
    bodyType: vehicle?.sku?.body?.type ?? '',
    stage: {
      newStageId: {
        title: 'Update Stage',
        label: 'Stage:',
        canEdited: false,
        renderedValue: formatVehicleStage(vehicle?.procurement?.currentStageId),
        type: 'dropdown',
        placeholder: '',
        defaultValue: vehicle?.procurement?.currentStageId,
        options: [],
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
    },
    information: {
      skuId: {
        title: 'Update SKU',
        label: 'SKU:',
        type: 'text',
        canEdited: true,
        placeholder: '',
        renderedValue: vehicle?.skuId || '-',
        defaultValue: vehicle?.skuId,
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      plate: {
        label: 'Plate:',
        renderedValue: vehicle?.plate || '-',
      },
      manufacturer: {
        label: 'Manufacturer:',
        renderedValue: vehicle?.manufacturer || '-',
      },
      model: {
        label: 'Model:',
        renderedValue: vehicle?.model || '-',
      },
      edition: {
        label: 'Edition:',
        renderedValue: vehicle?.sku?.edition || '-',
      },
      year: {
        label: 'Year:',
        renderedValue: vehicle?.sku?.year || '-',
      },
      vin: {
        title: 'Edit VIN (identification number)',
        label: 'VIN:',
        type: 'text',
        canEdited: true,
        placeholder: '',
        renderedValue: vehicle?.vin || '-',
        defaultValue: vehicle?.vin,
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      color: {
        title: 'Update Color',
        label: 'Color:',
        type: 'autocomplete',
        addNewOption: true,
        canEdited: false,
        valueProp: true,
        placeholder: '',
        renderedValue: '-',
        defaultValue: vehicle?.properties?.color,
        options: [],
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      condition: {
        label: 'Condition:',
        title: 'Update Condition',
        type: 'dropdown',
        placeholder: '',
        canEdited: true,
        renderedValue: vehicle?.condition || '-',
        defaultValue: vehicle?.condition,
        options: [
          { text: 'NEW', value: 'NEW' },
          { text: 'USED', value: 'USED' },
        ],
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      description: {
        label: 'Description',
        title: 'Update Description:',
        type: 'text',
        canEdited: true,
        placeholder: '',
        renderedValue: vehicle?.description || '-',
        defaultValue: vehicle?.description,
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
    },
    details: {
      createdAt: {
        label: 'Created At:',
        defaultValue: vehicle?.createdAt,
        type: 'date',
        disabled: true,
        renderedValue: vehicle?.createdAt
          ? formatDate(vehicle?.createdAt, 'DD/MM/YYYY')
          : '-',
      },
      updatedAt: {
        label: 'Updated At:',
        defaultValue: vehicle?.updatedAt,
        disabled: true,
        type: 'date',
        renderedValue: vehicle?.updatedAt
          ? formatDate(vehicle?.updatedAt, 'DD/MM/YYYY')
          : '-',
      },
      insuranceId: {
        title: 'Update Insurance',
        label: 'Insurance:',
        type: 'dropdown',
        placeholder: '',
        canEdited: false,
        renderedValue: vehicle?.insurance?.name || '-',
        defaultValue: vehicle?.insurance?.id,
        options: [],
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      roadsideAssistanceId: {
        title: 'Update Roadside',
        label: 'Roadside:',
        type: 'dropdown',
        placeholder: '',
        canEdited: false,
        renderedValue: vehicle?.roadsideAssistance?.name || '-',
        defaultValue: vehicle?.roadsideAssistance?.id,
        options: [],
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      insuranceRenewalDate: {
        title: 'Update Insurance Renewal Date',
        label: 'Insurance Renewal Date:',
        type: 'date',
        placeholder: '',
        canEdited: false,
        renderedValue: vehicle?.procurement?.insuranceRenewalDate
          ? formatDate(vehicle?.procurement?.insuranceRenewalDate, 'DD/MM/YYYY')
          : '-',
        defaultValue: vehicle?.procurement?.insuranceRenewalDate,
        options: [],
        onEditField: null,
        rules: {
          required: '* This field is required',
        },
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      activeLicense: {
        title: 'Active License',
        label: 'Active License:',
        type: 'dropdown',
        placeholder: '',
        canEdited: false,
        renderedValue: vehicle?.activeLicense
          ? findDocument(vehicle?.documents, vehicle?.activeLicense)?.filename
          : '-',
        defaultValue: vehicle?.activeLicense,
        options: vehicle?.documents?.map((item) => {
          return {
            text: `${item?.type} - ${item?.filename}`,
            value: item?.id,
          };
        }),
        link: findDocument(vehicle?.documents, vehicle?.activeLicense)?.url,
        target: '_blank',
        onEditField: null,
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      activeInsurance: {
        title: 'Active Insurance',
        label: 'Active Insurance:',
        type: 'dropdown',
        placeholder: '',
        canEdited: false,
        renderedValue: vehicle?.activeInsurance
          ? findDocument(vehicle?.documents, vehicle?.activeInsurance)?.filename
          : '-',
        defaultValue: vehicle?.activeInsurance,
        options: vehicle?.documents?.map((item) => {
          return {
            text: `${item?.type} - ${item?.filename}`,
            value: item?.id,
          };
        }),
        link: findDocument(vehicle?.documents, vehicle?.activeInsurance)?.url,
        target: '_blank',
        onEditField: null,
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      activeKteo: {
        title: 'Active KTEO',
        label: 'Active KTEO:',
        type: 'dropdown',
        placeholder: '',
        canEdited: false,
        renderedValue: vehicle?.activeKteo
          ? findDocument(vehicle?.documents, vehicle?.activeKteo)?.filename
          : '-',
        link: findDocument(vehicle?.documents, vehicle?.activeKteo)?.url,
        target: '_blank',
        defaultValue: vehicle?.activeKteo,
        options: vehicle?.documents?.map((item) => {
          return {
            text: `${item?.type} - ${item?.filename}`,
            value: item?.id,
          };
        }),
        onEditField: null,
        fieldWrapperStyle: {
          width: '100%',
        },
      },
      multimediaPin: {
        label: 'Multimedia PIN:',
        title: 'Multimedia PIN:',
        type: 'text',
        canEdited: true,
        placeholder: '',
        renderedValue: vehicle?.multimediaPin || '-',
        defaultValue: vehicle?.multimediaPin,
        onEditField: null,
        hasCopyValueButton: true,
        fieldWrapperStyle: {
          width: '100%',
        },
      },
    },
    color: vehicle?.properties?.color,
    dealers: [],
    financingProviders: [],
    properties: {
      engineDisplacement: {
        label: 'Engine Displacement:',
        renderedValue: vehicle?.properties?.engine?.displacement
          ? `${vehicle?.properties?.engine?.displacement?.value} ${vehicle?.properties?.engine?.displacement?.unitCode}`
          : '-',
      },
      transmission: {
        label: 'Transmission:',
        renderedValue: vehicle?.properties?.transmission || '-',
      },
      fuelType: {
        label: 'Fuel Type:',
        renderedValue: vehicle?.properties?.fuelType || '-',
      },
      power: {
        label: 'Engine Power:',
        renderedValue: vehicle?.properties?.engine?.power
          ? `${vehicle?.properties?.engine?.power?.value} ${vehicle?.properties?.engine?.power?.unitCode}`
          : '-',
      },
      mileageFromOdometer: {
        label: 'Mileage From Odometer:',
        renderedValue: vehicle?.mileageFromOdometer
          ? `${vehicle?.mileageFromOdometer?.value} ${vehicle?.mileageFromOdometer?.unitCode}`
          : '-',
      },
    },
    procurement: {
      created: {
        sourcingType: {
          canEdited: true,
          label: 'Sourcing Type:',
          type: 'dropdown',
          renderedValue: vehicle?.procurement?.sourcingType || '-',
          defaultValue: vehicle?.procurement?.sourcingType,
          options: [
            { text: 'OWNED', value: 'OWNED' },
            { text: 'R2R', value: 'R2R' },
          ],
          rules: {},
        },
      },
      order: {
        orderDate: {
          canEdited: true,
          label: 'Order Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.orderDate
            ? formatDate(vehicle?.procurement?.orderDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.orderDate,
          rules: {},
        },
        supplierCarId: {
          canEdited: true,
          label: 'Supplier Car Id:',
          renderedValue: vehicle?.procurement?.supplierCarId || '-',
          defaultValue: vehicle?.procurement?.supplierCarId,
          type: 'text',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        agreedCustomsDate: {
          canEdited: true,
          label: 'Agreed Customs Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.agreedCustomsDate
            ? formatDate(vehicle?.procurement?.agreedCustomsDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.agreedCustomsDate,
          rules: {},
        },
        estimatedCustomsDate: {
          canEdited: true,
          label: 'Estimated Customs Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.estimatedCustomsDate
            ? formatDate(
                vehicle?.procurement?.estimatedCustomsDate,
                'DD/MM/YYYY'
              )
            : '-',
          defaultValue: vehicle?.procurement?.estimatedCustomsDate,
          rules: {},
        },
        agreedPurchasePrice: {
          canEdited: true,
          label: 'Agreed Purchase Price:',
          renderedValue: vehicle?.procurement?.agreedPurchasePrice?.number
            ? `${vehicle?.procurement?.agreedPurchasePrice?.number} ${vehicle?.procurement?.agreedPurchasePrice?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.agreedPurchasePrice?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        dealer: {
          canEdited: true,
          label: 'Dealer:',
          renderedValue: vehicle?.procurement?.dealer || '-',
          defaultValue: vehicle?.procurement?.dealer,
          type: 'dropdown',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
          options: [],
        },
      },
      customs: {
        customsEntryDate: {
          canEdited: true,
          label: 'Customs Entry Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.customsEntryDate
            ? formatDate(vehicle?.procurement?.customsEntryDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.customsEntryDate,
          rules: {},
        },
      },
      paymentProcess: {
        plannedDownpaymentDate: {
          canEdited: true,
          label: 'Planned Downpayment Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.plannedDownpaymentDate
            ? formatDate(
                vehicle?.procurement?.plannedDownpaymentDate,
                'DD/MM/YYYY'
              )
            : '-',
          defaultValue: vehicle?.procurement?.plannedDownpaymentDate,
          rules: {},
        },
        vehicleOwnership: {
          canEdited: true,
          label: 'Vehicle Ownership:',
          type: 'dropdown',
          renderedValue: vehicle?.procurement?.vehicleOwnership || '-',
          defaultValue: vehicle?.procurement?.vehicleOwnership,
          options: [
            { text: 'Instacar', value: 'Instacar' },
            { text: 'Instachr', value: 'Instachr' },
            { text: 'Instapath', value: 'Instapath' },
            { text: 'Motorlease', value: 'Motorlease' },
            { text: 'NBG Leasing', value: 'NBG Leasing' },
            { text: 'Karenta', value: 'Karenta' },
            { text: 'Eurobank Leasing VGC', value: 'Eurobank Leasing VGC' },
            { text: 'Πειραιώς Leasing', value: 'Πειραιώς Leasing' },
            { text: 'Autobesikos', value: 'Autobesikos' },
            { text: 'Hertz/Autohellas', value: 'Hertz/Autohellas' },
            { text: 'Kinsen', value: 'Kinsen' },
            { text: 'EKKA', value: 'EKKA' },
            { text: 'Avance', value: 'Avance' },
            { text: 'Instacar/Hertz', value: 'Instacar/Hertz' },
          ],
          rules: {},
        },
        financingType: {
          canEdited: true,
          label: 'Financing Type:',
          type: 'dropdown',
          renderedValue: vehicle?.procurement?.financingType || '-',
          defaultValue: vehicle?.procurement?.financingType,
          options: [
            { text: 'Bank Loan 18M', value: 'Bank Loan 18M' },
            { text: 'Bank Loan 36M', value: 'Bank Loan 36M' },
            { text: 'Bank Loan 48M', value: 'Bank Loan 48M' },
            { text: 'Bond Loan', value: 'Bond Loan' },
            { text: 'Finance Lease 24M', value: 'Finance Lease 24M' },
            { text: 'Finance Lease 36M', value: 'Finance Lease 36M' },
            { text: 'Finance Lease 48M', value: 'Finance Lease 48M' },
            { text: 'Operating Lease 6M', value: 'Operating Lease 6M' },
            { text: 'Operating Lease 12M', value: 'Operating Lease 12M' },
            { text: 'Operating Lease 24M', value: 'Operating Lease 24M' },
            { text: 'Operating Lease 36M', value: 'Operating Lease 36M' },
            { text: 'Operating Lease 48M', value: 'Operating Lease 48M' },
          ],
          rules: {},
        },
        financingProvider: {
          canEdited: true,
          label: 'Financing Provider:',
          type: 'dropdown',
          renderedValue: vehicle?.procurement?.financingProvider || '-',
          defaultValue: vehicle?.procurement?.financingProvider,
          options: [],
          rules: {},
        },
        retailPrice: {
          canEdited: true,
          label: 'Retail Price:',
          renderedValue: vehicle?.procurement?.retailPrice?.number
            ? `${vehicle?.procurement?.retailPrice?.number} ${vehicle?.procurement?.retailPrice?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.retailPrice?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        retailPriceBeforeTax: {
          canEdited: true,
          label: 'Retail Price Before Tax:',
          renderedValue: vehicle?.procurement?.retailPriceBeforeTax?.number
            ? `${vehicle?.procurement?.retailPriceBeforeTax?.number} ${vehicle?.procurement?.retailPriceBeforeTax?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.retailPriceBeforeTax?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        vat: {
          canEdited: true,
          label: 'VAT:',
          renderedValue: vehicle?.procurement?.vat || '-',
          defaultValue: vehicle?.procurement?.vat,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        extras: {
          canEdited: true,
          label: 'Extras:',
          type: 'text',
          renderedValue: vehicle?.procurement?.extras?.length
            ? vehicle?.procurement?.extras?.join(',')
            : '-',
          defaultValue: vehicle?.procurement?.extras?.length
            ? vehicle?.procurement?.extras?.join(',')
            : '',
          instructions: 'Comma seperated',
          rules: {},
        },
        extrasPrice: {
          canEdited: true,
          label: 'Extras Price:',
          renderedValue: vehicle?.procurement?.extrasPrice?.number
            ? `${vehicle?.procurement?.extrasPrice?.number} ${vehicle?.procurement?.extrasPrice?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.extrasPrice?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        specialClassificationFee: {
          canEdited: true,
          label: 'Special Classification Fee:',
          renderedValue: vehicle?.procurement?.specialClassificationFee?.number
            ? `${vehicle?.procurement?.specialClassificationFee?.number} ${vehicle?.procurement?.specialClassificationFee?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.specialClassificationFee?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        classificationCosts: {
          canEdited: true,
          label: 'Classification Costs:',
          renderedValue: vehicle?.procurement?.classificationCosts?.number
            ? `${vehicle?.procurement?.classificationCosts?.number} ${vehicle?.procurement?.classificationCosts?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.classificationCosts?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        discountPercentage: {
          canEdited: true,
          label: 'Discount Percentage:',
          renderedValue: vehicle?.procurement?.discountPercentage || '-',
          defaultValue: vehicle?.procurement?.discountPercentage,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        discountWithoutVat: {
          canEdited: true,
          label: 'Discount Without Vat:',
          renderedValue: vehicle?.procurement?.discountWithoutVat?.number
            ? `${vehicle?.procurement?.discountWithoutVat?.number} ${vehicle?.procurement?.discountWithoutVat?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.discountWithoutVat?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        finalPurchasePrice: {
          canEdited: true,
          label: 'Final Purchase Price:',
          renderedValue: vehicle?.procurement?.finalPurchasePrice?.number
            ? `${vehicle?.procurement?.finalPurchasePrice?.number} ${vehicle?.procurement?.finalPurchasePrice?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.finalPurchasePrice?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        kilometersAtPurchase: {
          canEdited: true,
          label: 'Kilometers At Purchase:',
          renderedValue: vehicle?.procurement?.kilometersAtPurchase || '-',
          defaultValue: vehicle?.procurement?.kilometersAtPurchase,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        leaseMonthlyInstallment: {
          canEdited: true,
          label: 'Lease Monthly Installment:',
          renderedValue: vehicle?.procurement?.leaseMonthlyInstallment?.number
            ? `${vehicle?.procurement?.leaseMonthlyInstallment?.number} ${vehicle?.procurement?.leaseMonthlyInstallment?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.leaseMonthlyInstallment?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        buyOutPrice: {
          canEdited: true,
          label: 'Buyout Price:',
          renderedValue: vehicle?.procurement?.buyOutPrice?.number
            ? `${vehicle?.procurement?.buyOutPrice?.number} ${vehicle?.procurement?.buyOutPrice?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.buyOutPrice?.number,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
        buyOutMonths: {
          canEdited: true,
          label: 'Buyout Months:',
          renderedValue: vehicle?.procurement?.buyOutMonths || '-',
          defaultValue: vehicle?.procurement?.buyOutMonths,
          type: 'number',
          rules: {},
          fieldWrapperStyle: {
            width: '100%',
          },
        },
      },
      dealerPreparation: {
        licenseIssuanceDate: {
          canEdited: true,
          label: 'License Issuance Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.licenseIssuanceDate
            ? formatDate(
                vehicle?.procurement?.licenseIssuanceDate,
                'DD/MM/YYYY'
              )
            : '-',
          defaultValue: vehicle?.procurement?.licenseIssuanceDate,
          rules: {},
        },
        insuranceProvider: {
          canEdited: true,
          label: 'Insurance Provider:',
          type: 'text',
          renderedValue: vehicle?.procurement?.insuranceProvider || '-',
          defaultValue: vehicle?.procurement?.insuranceProvider,
          rules: {},
        },
        insuranceDate: {
          canEdited: true,
          label: 'Insurance Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.insuranceDate
            ? formatDate(vehicle?.procurement?.insuranceDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.insuranceDate,
          rules: {},
        },
        vehiclePurchaseDate: {
          canEdited: true,
          label: 'Vehicle Purchase Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.vehiclePurchaseDate
            ? formatDate(
                vehicle?.procurement?.vehiclePurchaseDate,
                'DD/MM/YYYY'
              )
            : '-',
          defaultValue: vehicle?.procurement?.vehiclePurchaseDate,
          rules: {},
        },
        leaseStartDate: {
          canEdited: true,
          label: 'Lease Start Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.leaseStartDate
            ? formatDate(vehicle?.procurement?.leaseStartDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.leaseStartDate,
          rules: {},
        },
        estimatedCarReceiptDate: {
          canEdited: true,
          label: 'Estimated Car Receipt Date:',
          type: 'date',
          disabled: true,
          renderedValue: vehicle?.procurement?.estimatedCarReceiptDate
            ? formatDate(
                vehicle?.procurement?.estimatedCarReceiptDate,
                'DD/MM/YYYY'
              )
            : '-',
          defaultValue: vehicle?.procurement?.estimatedCarReceiptDate,
          rules: {},
        },
        plannedReceiptDate: {
          canEdited: true,
          label: 'Planned Receipt Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.plannedReceiptDate
            ? formatDate(vehicle?.procurement?.plannedReceiptDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.plannedReceiptDate,
          rules: {},
        },
        receiptDate: {
          canEdited: true,
          label: 'Receipt Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.receiptDate
            ? formatDate(vehicle?.procurement?.receiptDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.receiptDate,
          rules: {},
        },
      },
      fleet: {
        currentLocation: {
          canEdited: true,
          label: 'Current Location:',
          type: 'dropdown',
          renderedValue: vehicle?.procurement?.currentLocation || '-',
          defaultValue: vehicle?.procurement?.currentLocation,
          rules: {},
          options: _getServicePointsOptions(),
        },
      },
      defleeted: {
        defleetedDate: {
          canEdited: false,
          label: 'Defleeted Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.defleetedDate
            ? formatDate(vehicle?.procurement?.defleetedDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.defleetedDate,
          rules: {},
        },
        defleetedReason: {
          canEdited: false,
          label: 'Reason:',
          type: 'textArea',
          renderedValue: vehicle?.procurement?.defleetedDate
            ? vehicle?.procurement?.cancelledDefleetedReason
            : '-',
          rules: {},
        },
        insuranceCancellationDate: {
          title: 'Update Insurance Cancellation Date',
          label: 'Insurance Cancellation Date:',
          type: 'date',
          placeholder: '',
          canEdited: true,
          onEditField: null,
          renderedValue: vehicle?.procurement?.insuranceCancellationDate
            ? formatDate(
                vehicle?.procurement?.insuranceCancellationDate,
                'DD/MM/YYYY'
              )
            : '-',
          defaultValue: vehicle?.procurement?.insuranceCancellationDate,
          rules: {},
        },
        transferCost: {
          canEdited: false,
          label: 'Transfer Cost:',
          type: 'number',
          renderedValue: vehicle?.procurement?.transferCost?.number
            ? `${vehicle?.procurement?.transferCost?.number} ${vehicle?.procurement?.transferCost?.currency}`
            : '-',
          defaultValue: vehicle?.procurement?.transferCost?.number,
          rules: {},
        },
      },
      canceled: {
        cancelledDate: {
          canEdited: true,
          label: 'Cancelled Date:',
          type: 'date',
          renderedValue: vehicle?.procurement?.cancelledDate
            ? formatDate(vehicle?.procurement?.cancelledDate, 'DD/MM/YYYY')
            : '-',
          defaultValue: vehicle?.procurement?.cancelledDate,
          rules: {},
        },
      },
    },
    services: vehicle?.services?.map((service) => {
      return {
        id: service.id,
        createdAt: service.createdAt ? formatDate(service.createdAt) : '-',
        updatedAt: service.updatedAt ? formatDate(service.updatedAt) : '-',
        vehicleServiceID: service.vehicleServiceID || '-',
      };
    }),
    costs: vehicle?.costs?.map((cost) => {
      return {
        id: cost.id,
        createdAt: cost.createdAt ? formatDate(cost.createdAt) : '-',
        updatedAt: cost.updatedAt ? formatDate(cost.updatedAt) : '-',
        type: cost.type || '-',
        amount: cost.amount ? formatAmount(cost.amount.value) : '-',
        comment: cost.comment || '-',
      };
    }),
    insurances: vehicle?.insurances?.map((insurance) => {
      return {
        id: insurance.id,
        amount: insurance.amount ? formatAmount(insurance.amount.value) : '-',
        comment: insurance.comment || '-',
        completedAt: insurance.completedAt
          ? formatDate(insurance.completedAt)
          : '-',
        createdAt: insurance.createdAt ? formatDate(insurance.createdAt) : '-',
        startedAt: insurance.startedAt ? formatDate(insurance.startedAt) : '-',
        updatedAt: insurance.updatedAt ? formatDate(insurance.updatedAt) : '-',
      };
    }),
    documents:
      vehicle?.documents?.map((document) => {
        return {
          id: document.id,
          createdAt: document.createdAt
            ? formatDate(document.createdAt, 'DD/MM/YYYY')
            : '-',
          filename: document.filename,
          type: document.type,
          url: document.url,
        };
      }) || [],
    servicePoints: [],
    existingServicePointsIds: [],
    servicePointsAll: [],
    invoiceDetails: {
      invoiceNumber: {
        label: 'Invoice Number',
        defaultValue: vehicle?.invoiceDetails?.invoiceNumber,
        type: 'text',
        renderedValue: vehicle?.invoiceDetails?.invoiceNumber || '-',
      },
      netPrice: {
        label: 'Net Price:',
        defaultValue: vehicle?.invoiceDetails?.netPrice?.number,
        type: 'number',
        renderedValue: vehicle?.invoiceDetails?.netPrice
          ? `${vehicle?.invoiceDetails?.netPrice?.number} ${vehicle?.invoiceDetails?.netPrice?.currency}`
          : '-',
      },
      registrationTax: {
        label: 'Registration Tax:',
        defaultValue: vehicle?.invoiceDetails?.registrationTax?.number,
        type: 'number',
        renderedValue: vehicle?.invoiceDetails?.registrationTax
          ? `${vehicle?.invoiceDetails?.registrationTax?.number} ${vehicle?.invoiceDetails?.registrationTax?.currency}`
          : '-',
      },
      vat: {
        label: 'Vat:',
        defaultValue: vehicle?.invoiceDetails?.vat?.number,
        type: 'number',
        renderedValue: vehicle?.invoiceDetails?.vat
          ? `${vehicle?.invoiceDetails?.vat?.number} ${vehicle?.invoiceDetails?.vat?.currency}`
          : '-',
      },
      transferFee: {
        label: 'Transfer Fee:',
        defaultValue: vehicle?.invoiceDetails?.transferFee?.number,
        type: 'number',
        renderedValue: vehicle?.invoiceDetails?.transferFee
          ? `${vehicle?.invoiceDetails?.transferFee?.number} ${vehicle?.invoiceDetails?.transferFee?.currency}`
          : '-',
      },
      plateReleaseFee: {
        label: 'PlateRelease Fee:',
        defaultValue: vehicle?.invoiceDetails?.plateReleaseFee?.number,
        type: 'number',
        renderedValue: vehicle?.invoiceDetails?.plateReleaseFee
          ? `${vehicle?.invoiceDetails?.plateReleaseFee?.number} ${vehicle?.invoiceDetails?.plateReleaseFee?.currency}`
          : '-',
      },
    },
  };

  return data;
};

export const addFinancingProviders = (vehicle, financingProviders) => {
  if (!vehicle) return null;
  if (
    !financingProviders?.length ||
    !vehicle?.procurement?.paymentProcess?.financingProvider?.options
  )
    return vehicle;

  vehicle.procurement.paymentProcess.financingProvider.options =
    financingProviders
      ? Object?.keys(financingProviders)?.map((provider) => {
          return {
            text: financingProviders[provider]?.name,
            value: financingProviders[provider]?.name,
          };
        })
      : [];
  return vehicle;
};

export const addDealers = (vehicle, dealers) => {
  if (!vehicle) return null;
  if (!dealers?.length || !vehicle?.procurement?.order?.dealer?.options)
    return vehicle;

  vehicle.procurement.order.dealer.options = dealers?.map((dealer) => {
    return {
      value: dealer?.name,
      text: dealer?.name,
    };
  });
  return vehicle;
};

export const addServicePointsAll = (vehicle, servicePointsAll) => {
  if (!vehicle) return null;
  if (!servicePointsAll?.length) return vehicle;

  vehicle.servicePointsAll = servicePointsAll
    ?.map((servicePoint) => {
      return { text: servicePoint?.name || '', value: servicePoint?.id };
    })
    ?.sort((a, b) => {
      const nameA = a.text.toUpperCase().trim();
      const nameB = b.text.toUpperCase().trim();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }

      return 0;
    })
    ?.reverse();
  return vehicle;
};

export const addServicePoints = (vehicle, servicePoints) => {
  if (!vehicle) return null;
  if (!servicePoints?.length) return vehicle;

  vehicle.servicePoints = servicePoints?.map((point) => {
    return {
      id: point.servicePoint.id,
      name: point.servicePoint.name || '',
      types: point.servicePoint.supportedWorkTypes || [],
    };
  });
  vehicle.existingServicePointsIds = servicePoints?.map((point) => {
    return point.servicePoint.id;
  });

  return vehicle;
};

export const addColors = (vehicle, colors) => {
  if (!vehicle) return null;
  if (!colors?.length) return vehicle;
  // const vehicleColor = findNameColor(colors, vehicle?.color);
  // console.log('vehicleColor = ', vehicleColor);
  vehicle.information.color.renderedValue = vehicle?.color;
  vehicle.information.color.canEdited = true;
  vehicle.information.color.options = colors?.map((color) => {
    return {
      label: color?.name,
      value: color?.name,
    };
  });

  return vehicle;
};

export const addRoadsideAssistance = (vehicle, roadsideAssistances) => {
  if (!vehicle) return null;
  if (!roadsideAssistances?.length) return vehicle;
  vehicle.details.roadsideAssistanceId.canEdited = true;
  vehicle.details.roadsideAssistanceId.options = roadsideAssistances?.map(
    (roadsideAssistance) => {
      return {
        text: roadsideAssistance?.name,
        value: roadsideAssistance?.id,
      };
    }
  );
  return vehicle;
};

export const addInsurances = (vehicle, insurances) => {
  if (!vehicle) return null;
  if (!insurances?.length) return vehicle;
  vehicle.details.insuranceId.canEdited = true;
  vehicle.details.insuranceId.options = insurances?.map((insurance) => {
    return {
      text: insurance?.name,
      value: insurance?.id,
    };
  });
  return vehicle;
};

export const addHistory = (vehicle, history) => {
  const mapTypes = {
    [BOARDS_ID.ARM_ACCIDENTS]: { title: 'New Accident', key: 'arnmAccidents' },
    [BOARDS_ID.ARM_REPAIRS]: { title: 'New Repair', key: 'arnmRepairs' },
    [BOARDS_ID.ARM_MAINTENANCE]: {
      title: 'New Maintenance',
      key: 'arnmMaintenance',
    },
  };

  if (!vehicle) {
    return null;
  }
  vehicle.history = history.map((item) => ({
    ...item,
    field: [mapTypes[item.type]?.title],
    task: {
      id: item.id,
      link: `/${mapTypes[item.type]?.key}/${item.id}`,
    },
  }));

  return vehicle;
};
