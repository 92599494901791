import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { roadsideAssistanceServices } from '@services';
import { handleRoadsideAssistanceData } from './utils';

export const useRoadsideAssistanceOperations = () => {
  const router = useRouter();
  const roadsideAssistanceId = router.query?.roadsideAssistanceId;

  const roadsideAssistanceResponse = useQuery(
    [QUERY_KEYS.GET_ROADSIDE_ASSISTANCE_KEY, roadsideAssistanceId],
    () =>
      roadsideAssistanceServices.getRoadsideAssistance(roadsideAssistanceId),
    { enabled: !!roadsideAssistanceId }
  );

  const roadsideAssistance = handleRoadsideAssistanceData(
    roadsideAssistanceResponse?.data?.data
  );

  useEffect(() => {
    if (roadsideAssistanceResponse?.isError) {
      showError(roadsideAssistanceResponse.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roadsideAssistanceResponse.isError]);

  return {
    loading: roadsideAssistanceResponse?.isLoading,
    roadsideAssistance,
    getRoadsideAssistance: roadsideAssistanceResponse?.refetch,
  };
};
