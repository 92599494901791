import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { subscriptionsServices } from '@services';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import {
  getSubscriptionsQueryParams,
  getSubscriptionsRequestPayload,
} from 'helpers';
import { handleSubscriptions } from './subscriptions.handlers';

export const SUBSCRIPTIONS_LIMIT = 100;
export const SUBSCRIPTIONS_EXPORT_LIMIT = 20000;

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  subscriptions: [],
  filters: {
    urlParams: undefined,
    modalFiltersRules: undefined,
    modalConditions: undefined,
  },
  total: 0,
  pageSize: SUBSCRIPTIONS_LIMIT,
};
// Actual Slice
export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    fetchSubscriptions: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.subscriptions = [];
    },
    fetchSubscriptionsSuccess: (state, { payload }) => {
      state.subscriptions = handleSubscriptions(payload?.response?.data);
      state.total = payload?.response?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
      state.filters.modalFiltersRules = payload.modalFiltersRules;
      state.filters.urlParams = payload.urlParams;
    },
    fetchSubscriptionsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchSubscriptions,
  fetchSubscriptionsSuccess,
  fetchSubscriptionsFailure,
} = subscriptionsSlice.actions;

export const selectSubscriptionsState = (state) => state.subscriptions;

export default subscriptionsSlice.reducer;

export const getSubscriptions = ({
  urlParams = {},
  modalFiltersRules = null,
}) => {
  return async (dispatch) => {
    dispatch(fetchSubscriptions());
    try {
      const params = getSubscriptionsQueryParams(urlParams);
      const payload = getSubscriptionsRequestPayload(
        urlParams,
        modalFiltersRules
      );
      const response = await subscriptionsServices?.getSubscriptions({
        params,
        payload,
      });
      response.meta.page = urlParams.page || 1;

      dispatch(
        fetchSubscriptionsSuccess({ response, urlParams, modalFiltersRules })
      );
    } catch (error) {
      dispatch(fetchSubscriptionsFailure(error));
    }
  };
};
