import { FILTER_RULE_ENUMS } from '@constants';

export const handleServiceVehiclesRequestPayload = (router) => {
  let payload = {};
  const filterRules = [];

  if (router.query?.brand) {
    filterRules.push({
      field: 'vehicleName',
      operator: 9,
      type: 9,
      value: router.query.brand,
    });
  }
  if (router.query?.requestType) {
    filterRules.push({
      field: 'type',
      operator: 0,
      type: 5,
      value: router.query.requestType,
    });
  }
  if (router.query?.status) {
    filterRules.push({
      field: 'status',
      operator: 0,
      type: 5,
      value: router.query.status,
    });
  }

  if (filterRules.length) {
    payload = {
      type: 0,
      field: null,
      operator: null,
      value: filterRules,
    };
  } else {
    payload = {
      type: FILTER_RULE_ENUMS.EmptyRuleType,
    };
  }

  return payload;
};
