import { formatDate, generateShortId } from '@utils';

export const handleServicePointsData = (array) => {
  if (!array) {
    return [];
  }
  const data = array?.map((item) => {
    return {
      status: item.status,
      email: item?.email || '-',
      internalId: item?.internalId || '-',
      name: { name: item.name, isCertified: item.partnershipInfo.isCertified },
      slug: item?.slug || '-',
      telephoneNumber: item?.telephoneNumber || '-',
      id: item?.id,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      updatedAt: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY')
        : '-',
      primaryContactName:
        item?.contacts?.find((contact) => contact?.isPrimary)?.fullName || '-',
      primaryContactNumber:
        item?.contacts?.find((contact) => contact?.isPrimary)?.phoneNumber[0]
          ?.phoneNumber || '-',
      region: item?.addresses?.[0]?.locality || '-',
      workdays: item?.workdays || [],
    };
  });
  return data;
};

export const handleServicePointData = (item) => {
  if (!item?.id) {
    return {};
  }
  const servicePoint = {
    email: {
      value: item?.email || '-',
    },
    internalId: {
      value: item?.internalId || '-',
      name: 'internalId',
    },
    name: {
      value: item?.name || '-',
      name: 'name',
    },
    slug: {
      value: item?.slug || '-',
    },
    telephoneNumber: {
      value: item?.telephoneNumber || '-',
      name: 'telephoneNumber',
    },
    id: item?.id,
    createdAt: {
      value: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY HH:mm')
        : '-',
    },
    updatedAt: {
      value: item?.updatedAt
        ? formatDate(item?.updatedAt, 'DD/MM/YYYY HH:mm')
        : '-',
    },
    isCertified: item?.partnershipInfo?.isCertified || false,
    supportedManufacturers: {
      value: item?.supportedManufacturers || [],
      name: 'supportedManufacturers',
    },
    supportedWorkTypes: {
      value: item?.supportedWorkTypes || [],
      name: 'supportedWorkTypes',
    },
    supportedFuelTypes: {
      value: item?.supportedFuelTypes || [],
      name: 'supportedFuelTypes',
    },
    supportedInsurances: {
      value: item?.supportedInsurances || [],
      name: 'supportedInsurances',
    },
    supportedProviders: {
      value: item?.supportedProviders || [],
      name: 'supportedProviders',
    },
    workdays: {
      value: item?.workdays || [],
      name: 'workdays',
    },
    supportedBanks: {
      value: item?.billingData?.bank || '-',
      name: 'supportedBanks',
    },
    bankIban: {
      value: item?.billingData?.bankIban || '-',
      name: 'bankIban',
    },
    contacts:
      item?.contacts?.map((con) => {
        return {
          ...con,
          phoneNumber: con?.phoneNumber[0],
          phoneNumberSecondary: con?.phoneNumber[1],
        };
      }) || [],
    partnershipInfo: item?.partnershipInfo || {},
  };

  if (!item?.addresses?.length) {
    servicePoint.addresses = [];
  } else {
    servicePoint.addresses = item?.addresses?.map((address) => {
      return {
        country: address?.country || '-',
        createdAt: address?.createdAt
          ? formatDate(address?.createdAt, 'DD/MM/YYYY')
          : '-',
        id: address?.id,
        latitude: address?.latitude || 0,
        locality: address?.locality || '-',
        longitude: address?.longitude || 0,
        postalCode: address?.postalCode || '-',
        region: address?.region || '-',
        streetAddress: address?.streetAddress || '-',
        streetNumber: address?.streetNumber || '-',
        updatedAt: address?.updatedAt
          ? formatDate(address?.updatedAt, 'DD/MM/YYYY')
          : '-',
      };
    });
  }
  return servicePoint;
};

export const handleServicePointsEventsData = (array) => {
  if (!array) {
    return [];
  }

  const events = [];
  array?.forEach((event) => {
    events?.push({
      ...event,
      id: generateShortId(),
    });
  });

  return events;
};
