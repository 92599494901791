import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  agentsServices,
  boardsServices,
  fleetVehiclesServices,
} from '@services';
import { GENERIC_ERROR_MESSAGE, LIMIT } from '@constants';
import { groupBy } from 'lodash';
import { BUY_OUT_TASK_STATUSES_NAMES } from 'components/buyout/constants';
import { showError } from '@utils';
import {
  handleBuyOutData,
  handleBuyOutTaskData,
  handleVehicleData,
  handleAssigneeData,
} from './buyout.handlers';

export const BUYOUT_LIMIT = 1000;

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  buyOut: [],
  buyOutTask: {},
  vehicle: {},
  assignees: {
    data: [],
    loading: false,
  },
  total: 0,
  pageSize: LIMIT,
  postLoading: false,
  postHasErrors: false,
  postErrorMessage: '',
  postSuccess: false,
};
// Actual Slice
export const buyOutSlice = createSlice({
  name: 'buyOut',
  initialState,
  reducers: {
    fetchBuyOut: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.returnTask = {};
      state.subTasks = [];
      state.buyOutTask = {};
    },
    fetchBuyOutSuccess: (state, { payload }) => {
      state.buyOut = handleBuyOutData(
        payload?.buyOuts.data,
        payload?.assignees?.data
      );
      state.total = payload?.buyOut?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchBuyOutTaskSuccess: (state, { payload }) => {
      state.buyOutTask = handleBuyOutTaskData(payload?.task);
      state.vehicle = handleVehicleData(payload?.vehicle);
      state.loading = false;
      state.errorMessage = '';
    },
    fetchBuyOutFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchBuyoutAssignees: (state) => {
      state.assignees.loading = true;
      state.assignees.hasErrors = false;
      state.assignees.errorMessage = '';
    },
    fetchBuyoutAssigneesSuccess: (state, { payload }) => {
      state.assignees.loading = false;
      state.assignees.data = handleAssigneeData(payload.assignees);
    },
    fetchBuyoutAssigneesFailure: (state, { payload }) => {
      state.assignees.loading = false;
      state.assignees.data = [];
      state.assignees.hasErrors = true;
      state.assignees.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchBuyOut,
  fetchBuyOutSuccess,
  fetchBuyOutTaskSuccess,
  fetchBuyOutFailure,
  fetchBuyoutAssignees,
  fetchBuyoutAssigneesSuccess,
  fetchBuyoutAssigneesFailure,
} = buyOutSlice.actions;

export const selectBuyOutState = (state) => state.buyOut;
export const selectBuyOutsColumnsState = ({ buyOut }) => {
  const { tasks } = buyOut?.buyOut || {};
  const order = [0, 4, 5, 7, 6, 8, 9, 10, 3, 11, 12];
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: BUY_OUT_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: BUY_OUT_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: BUY_OUT_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};

export const getBuyOut = (payload) => {
  return async (dispatch) => {
    dispatch(fetchBuyOut());

    try {
      const buyOuts = await boardsServices.getBoardsFilters(payload);
      const assignees = await agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
      dispatch(fetchBuyOutSuccess({ buyOuts, assignees }));
    } catch (error) {
      dispatch(fetchBuyOutFailure(error));
    }
  };
};

export const getBuyOutTask = (taskId) => {
  return async (dispatch) => {
    dispatch(fetchBuyOut());

    try {
      const task = await boardsServices.getTaskById(taskId);
      const carId = task?.data?.customFields?.carId;
      let vehicle = {};
      if (carId) {
        vehicle = await fleetVehiclesServices.getVehicleByInternalId(carId);
      }

      dispatch(
        fetchBuyOutTaskSuccess({ task: task?.data, vehicle: vehicle?.data })
      );
    } catch (error) {
      dispatch(fetchBuyOutFailure(error));
    }
  };
};

export const getBuyoutAssignees = (payload = {}) => {
  return async (dispatch) => {
    dispatch(fetchBuyoutAssignees());

    try {
      const assignees = await agentsServices.getAgents(payload);
      dispatch(
        fetchBuyoutAssigneesSuccess({
          assignees: assignees?.data || [],
        })
      );
    } catch (error) {
      showError(error);
      dispatch(fetchBuyoutAssigneesFailure(error));
    }
  };
};
export default buyOutSlice.reducer;
