import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { agentsServices, boardsServices } from '@services';
import { showError } from '@utils';
import { GENERIC_ERROR_MESSAGE } from '@constants';
import { groupBy } from 'lodash';
import { ARNM_ACCIDENTS_TASK_STATUSES_NAMES } from 'components/arnmAccidents/constants';
import {
  handleArnmAccidentsData,
  handleAssigneeData,
} from './arnmAccidents.handlers';

// Initial state
const initialState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  arnmAccidents: [],
  assignees: {
    data: [],
    loading: false,
  },
  total: 0,
  pageSize: 1000,
};
// Actual Slice
export const arnmAccidentsSlice = createSlice({
  name: 'arnmAccidents',
  initialState,
  reducers: {
    fetchArnmAccidents: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMessage = '';
      state.returnTask = {};
      state.subTasks = [];
    },
    fetchArnmAccidentsSuccess: (state, { payload }) => {
      state.arnmAccidents = handleArnmAccidentsData(
        payload?.response.data,
        payload?.assignees?.data
      );
      state.total = payload?.meta?.total || 0;
      state.loading = false;
      state.hasErrors = false;
      state.errorMessage = '';
    },
    fetchArnmAccidentsFailure: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    fetchArnmAssignees: (state) => {
      state.assignees.loading = true;
      state.assignees.hasErrors = false;
      state.assignees.errorMessage = '';
    },
    fetchArnmAssigneesSuccess: (state, { payload }) => {
      state.assignees.loading = false;
      state.assignees.data = handleAssigneeData(payload.assignees);
    },
    fetchArnmAssigneesFailure: (state, { payload }) => {
      state.assignees.loading = false;
      state.assignees.data = [];
      state.assignees.hasErrors = true;
      state.assignees.errorMessage = payload || GENERIC_ERROR_MESSAGE;
    },
    // Special reducer for hydrating the state. Special case for next-redux-wrapper
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.errors,
        };
      },
    },
  },
});

export const {
  fetchArnmAccidents,
  fetchArnmAccidentsSuccess,
  fetchArnmAccidentsFailure,
  fetchArnmAssignees,
  fetchArnmAssigneesSuccess,
  fetchArnmAssigneesFailure,
} = arnmAccidentsSlice.actions;

export const selectArnmAccidentsState = (state) => state.arnmAccidents;
export const selectArnmAccidentsColumnsState = ({ arnmAccidents }) => {
  const { tasks } = arnmAccidents?.arnmAccidents || {};
  const order = [0, 1, 2, 13, 14, 16, 17, 18, 19, 20, 21, 3, 12];
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: ARNM_ACCIDENTS_TASK_STATUSES_NAMES[cur].label,
      cards: groupedByStatus[cur] || [],
      styles: ARNM_ACCIDENTS_TASK_STATUSES_NAMES[cur].styles,
      value: true,
      label: ARNM_ACCIDENTS_TASK_STATUSES_NAMES[cur].label,
      key: cur,
    });
    return acc;
  }, []);

  return columns;
};
export const getArnmAccidents = (payload) => {
  return async (dispatch) => {
    dispatch(fetchArnmAccidents());
    const page = parseInt(payload?.page, 10);

    try {
      const response = await boardsServices.getBoardsFilters(payload);
      response.meta.page = page;
      const assignees = await agentsServices.getAgents({
        page: 1,
        pageSize: 3000,
      });
      dispatch(fetchArnmAccidentsSuccess({ response, assignees }));
    } catch (error) {
      dispatch(fetchArnmAccidentsFailure(error));
    }
  };
};

export const getArnmAccidentsAssignees = (payload = {}) => {
  return async (dispatch) => {
    dispatch(fetchArnmAssignees());

    try {
      const assignees = await agentsServices.getAgents(payload);
      dispatch(
        fetchArnmAssigneesSuccess({
          assignees: assignees?.data || [],
        })
      );
    } catch (error) {
      showError(error);
      dispatch(fetchArnmAssigneesFailure(error));
    }
  };
};

export default arnmAccidentsSlice.reducer;
