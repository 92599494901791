import { ARNM_REASONS_MAP, BOARDS_ID } from '@constants';
import { formatDate } from '@utils';

export const handleAssigneeData = (data = []) =>
  // TODO: Fix when data is empty
  data
    ?.sort((a, b) => {
      return a.firstName.localeCompare(b.firstName);
    })
    ?.map((item) => ({
      isActive: item.isActive,
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
    }));

export const handleArnmMaintenanceData = (data, assignees) => {
  if (!data.length > 0) {
    return {};
  }

  const assigneesMap = assignees.reduce((acc, cur) => {
    acc[cur.email] = {
      ...cur,
      shortName: `${cur.firstName?.[0] || ''}${
        cur.lastName?.[0] || ''
      }`.toUpperCase(),
    };
    return acc;
  }, {});
  const tasks = [];
  data?.forEach((item) => {
    const assignee = assigneesMap[item.assignee] || {};

    const newTasks = {
      ...item,
      href: `/arnmMaintenance/${item?.id}`,
      status: item?.status,
      assignee,
      description: item?.name,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      // TODO: handle custom fields
      id: item?.id,
      colorName: item?.customFields?.doWeNeedATempCar && 'red',
      servicePoint: item?.customFields?.servicePoint || '-',
      reason: ARNM_REASONS_MAP[item?.customFields?.reason] || '-',
      outDateFromServicePoint: item?.customFields?.outDateFromServicePoint
        ? formatDate(item?.customFields?.outDateFromServicePoint, 'DD/MM/YYYY')
        : '-',
    };
    tasks.push(newTasks);
  });

  return {
    boardId: BOARDS_ID.ARM_MAINTENANCE,
    tasks,
  };
};
