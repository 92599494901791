import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { showError } from '@utils';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/queryKeys';
import { boardsServices, fleetVehiclesServices } from '@services';
import { handleGreenCardTaskData, handleVehicleData } from './utils';

export const useGreenCardTaskOperations = () => {
  const router = useRouter();
  const greenCardTaskId = router.query?.greenCardTaskId;

  const greenCardTaskResponse = useQuery(
    [QUERY_KEYS.GET_GREEN_CARD_TASK_KEY, greenCardTaskId],
    () => boardsServices.getTaskById(greenCardTaskId),
    {
      enabled: !!greenCardTaskId,
    }
  );

  const greenCardTask = handleGreenCardTaskData(
    greenCardTaskResponse?.data?.data
  );
  const carId = greenCardTask.customFields?.carId;

  const vehicleResponse = useQuery(
    [QUERY_KEYS.GET_VEHICLE_BY_INTERNAL_ID_KEY, greenCardTaskId],
    () => fleetVehiclesServices.getVehicleByInternalId(carId),
    {
      enabled: greenCardTaskResponse?.isFetched && !!carId,
    }
  );

  const vehicle = handleVehicleData(vehicleResponse?.data?.data);

  useEffect(() => {
    if (greenCardTaskResponse?.isError) {
      showError(greenCardTaskResponse?.error);
    }
  }, [greenCardTaskResponse?.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleResponse?.isError) {
      showError(vehicleResponse?.error);
    }
  }, [vehicleResponse?.isError]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: greenCardTaskResponse?.isFetching || vehicleResponse?.isFetching,
    task: greenCardTask,
    taskId: greenCardTaskId,
    getGreenCardTask: greenCardTaskResponse?.refetch,
    vehicle,
  };
};
