import { BOARDS_ID, LIMIT } from '@constants';
import { formatDate, getCleanedUrlParams, sortArray } from '@utils';
import { URLS } from 'layouts/DrawerLayout/constants';
import { groupBy, pick } from 'lodash';
import {
  TRAFFIC_OFFENSES_REASONS,
  TRAFFIC_OFFENSES_STATUSES,
} from 'components/trafficOffenses/constants';
import { TRAFFIC_OFFENSES_BOARD } from './constants';

export const getTrafficOffensesQueryParams = (payload = {}) => {
  const customFields = [];

  if (!payload?.pageSize) {
    payload.pageSize = LIMIT;
  }
  if (!payload?.page) {
    payload.page = '1';
  }

  if (!payload.order) {
    payload.order = 'desc';
    payload.orderBy = 'createdAt';
  }

  Object.keys(payload)?.forEach((key) => {
    if (customFields.includes(key)) {
      payload[`customFields.${key}`] = payload[key];
      delete payload[key];
    }
  });

  return getCleanedUrlParams(
    pick(payload, ['page', 'pageSize', 'order', 'orderBy'])
  );
};

export const getTrafficOffensesPayload = (params) => {
  const defaultRule = {
    type: 5,
    field: 'boardId',
    operator: 0,
    value: BOARDS_ID.TRAFFIC_OFFENSES,
  };
  return {
    ...params,
    ...defaultRule,
  };
};

export const normaliseAssigneesData = (data = []) =>
  data
    .map((item) => ({
      isActive: item?.isActive,
      value: item.email,
      text:
        item.firstName && item.lastName
          ? `${item?.firstName} ${item?.lastName}`
          : item.email,
      shortName:
        item.firstName && item.lastName
          ? `${item?.firstName[0].toUpperCase()}${item?.lastName[0].toUpperCase()}`
          : item.email[0].toUpperCase(),
    }))
    ?.sort((a, b) => a.text.localeCompare(b.text));

export const normalizeServicePointsTaskData = (servicePoints) => {
  if (!servicePoints) {
    return [];
  }
  return servicePoints?.map((item) => {
    const primaryContact = item?.contacts?.find(
      (contact) => contact?.isPrimary === true
    );
    return {
      text: item?.name,
      value: item?.id,
      email: primaryContact?.email,
    };
  });
};

export const normaliseTrafficOffensesTasks = (data = [], assignees = []) => {
  if (!data.length > 0) {
    return {};
  }

  const tasks = data.map((item) => {
    const assignee = assignees.find((a) => a.value === item.assignee) || {};

    return {
      ...item,
      id: item?.id,
      assignee,
      assigneeColumn: assignee?.text || '-',
      plate: item.customFields?.vehiclePlate || '',
      description: `${item.name}`,
      href: `${URLS.trafficOffenses}/${item?.id}`,
      userId: item.customFields.userId,
      userFullName: `${item.customFields.userFirstName || ''} ${
        item.customFields.userLastName || ''
      }`,
      carDescr: `${item.customFields.vehicleDescription} - ${item.customFields.vehiclePlate}`,
      createdAt: item?.createdAt
        ? formatDate(item?.createdAt, 'DD/MM/YYYY')
        : '-',
      date: item.createdAt ? formatDate(item.createdAt, 'DD/MM/YYYY') : null,
      status: item.status,
      columnStatus: TRAFFIC_OFFENSES_STATUSES[item.customFields.status] || '-',
      reason: TRAFFIC_OFFENSES_REASONS[item.customFields?.reason] || '-',
      dateOfViolation: item.customFields?.dateOfViolation
        ? formatDate(item.customFields?.dateOfViolation, 'DD/MM/YYYY')
        : '-',
      violationNumber: item.customFields.violationNumber || '-',
    };
  });

  return {
    tasks: tasks?.sort((a, b) => {
      return b.id.localeCompare(a.id);
    }),
  };
};

export const getColumns = (tasks = []) => {
  const order = TRAFFIC_OFFENSES_BOARD.statusOrder;
  const groupedByStatus = groupBy(tasks, 'status');

  const columns = order.reduce((acc, cur) => {
    acc.push({
      id: cur,
      title: TRAFFIC_OFFENSES_BOARD?.statuses?.[cur]?.label || cur,
      cards: groupedByStatus[cur] || [],
      styles: TRAFFIC_OFFENSES_BOARD?.statuses?.[cur]?.styles,
      value: true,
      label: TRAFFIC_OFFENSES_BOARD?.statuses?.[cur]?.label || cur,
      key: cur,
    });

    return acc;
  }, []);

  return columns;
};

export const handleVehicleData = (data) => {
  if (!data) {
    return {};
  }
  const vehicle = {
    images: data?.images,
    title: `${data?.sku?.brand || ''} ${data?.sku?.model || ''} ${
      data?.sku?.edition || ''
    } ${data?.properties?.color || ''} ${data?.year || ''} - ${
      data?.plate || ''
    }`,
    subTitle: `${data?.internalId ? `${data?.internalId} -` : '' || ''} ${
      data?.vin ? `${data?.vin} -` : ''
    } ${
      data?.procurement?.insuranceProvider
        ? `${data?.procurement?.insuranceProvider} -`
        : ''
    } ${
      data?.properties?.transmission
        ? `${data?.properties?.transmission} -`
        : ''
    } ${data?.properties?.fuelType}`,
    ownershipInfo: `${
      data?.procurement?.vehicleOwnership
        ? `${data?.procurement?.vehicleOwnership} -`
        : ''
    } ${data?.procurement?.financingProvider || ''}`,
  };
  return vehicle;
};

export const handleTrafficOffensesTaskData = (data = {}, assignees = []) => {
  if (!data) {
    return {};
  }

  const task = {
    ...data,
    assignee: assignees.find((item) => item?.value === data?.assignee),
    customFields: {
      ...data.customFields,
      reason: data.customFields?.reason || '-',
      totalChargeClientAmount:
        data.customFields?.totalChargeClientAmount?.number || null,
      totalInvoiceCost: data.customFields?.totalInvoiceCost?.number || null,
      violationFee: data.customFields?.violationFee?.number || null,
    },
    changelog: data?.changelog?.reverse(),
    comments: data?.comments
      ? sortArray(data?.comments, 'createdAt', 'desc')?.map((entry) => {
          return {
            id: entry?.id,
            date: entry?.createdAt
              ? formatDate(entry?.createdAt, 'DD MMMM YYYY')
              : '-',
            time: entry?.createdAt
              ? formatDate(entry?.createdAt, 'HH:mm')
              : '-',
            name: entry?.userFullName || entry?.user || '-',
            comment: entry?.comment,
            edit: entry?.editedAt
              ? formatDate(entry?.editedAt, 'DD MMMM YYYY HH:mm')
              : '',
          };
        })
      : [''],
    documents: data?.documents?.filter(
      (item) => !item?.tags?.includes('chargeEmailPhotosAndDocs')
    ),
    chargeEmailPhotosAndDocs: data?.documents?.filter((item) =>
      item?.tags?.includes('chargeEmailPhotosAndDocs')
    ),
  };
  return task;
};

export const handleAssigneeData = (data = []) =>
  data.map((item) => ({
    isActive: item?.isActive,
    value: item?.email,
    text:
      item?.firstName && item?.lastName
        ? `${item?.firstName} ${item?.lastName}`
        : item?.email,
  }));
