// Gerakas
export const LOCATION_GERAKAS = 'instacar point - Gerakas';
export const STREET_NAME_GERAKAS = 'Κλεισθένους';
export const STREET_NUMBER_GERAKAS = 234;
export const LOCALITY_GERAKAS = 'Γέρακας';
export const POSTAL_CODE_GERAKAS = '153 44';
export const REGION_GERAKAS = 'Περιφερειακή ενότητα Βορείου Τομέα Αθηνών';
export const DEFAULT_COUNTRY_GERAKAS = 'Ελλάδα';
export const DEFAULT_LAT_GERAKAS = 38.028584;
export const DEFAULT_LNG_GERAKAS = 23.853242;

// Aspropirgos
export const LOCATION_ASPROPIRGOS = 'instacar point - Aspropirgos';
export const STREET_NAME_ASPROPIRGOS = 'Γκορυτσάς';
export const STREET_NUMBER_ASPROPIRGOS = '';
export const LOCALITY_ASPROPIRGOS = 'Ασπρόπυργος';
export const POSTAL_CODE_ASPROPIRGOS = '193 00';
export const REGION_ASPROPIRGOS = 'Περιφερειακή ενότητα Βορείου Τομέα Αθηνών';
export const DEFAULT_COUNTRY_ASPROPIRGOS = 'Ελλάδα';
export const DEFAULT_LAT_ASPROPIRGOS = 38.068814006444256;
export const DEFAULT_LNG_ASPROPIRGOS = 23.58023802933995;

// Argyroupoli
export const LOCATION_ARGYROUPOLI = 'instacar point - Argyroupoli';
export const STREET_NAME_ARGYROUPOLI = 'Αργυρουπόλεως';
export const STREET_NUMBER_ARGYROUPOLI = 100;
export const LOCALITY_ARGYROUPOLI = 'Αργυρούπολη';
export const POSTAL_CODE_ARGYROUPOLI = '164 51';
export const REGION_ARGYROUPOLI = 'Περιφερειακή ενότητα Νότιου Τομέα Αθηνών';
export const DEFAULT_COUNTRY_ARGYROUPOLI = 'Ελλάδα';
export const DEFAULT_LAT_ARGYROUPOLI = 37.91593704757835;
export const DEFAULT_LNG_ARGYROUPOLI = 23.755148881626514;

// Athens International Airport "Eleftherios Venizelos"
export const LOCATION_ATHENS_INTERNATIONAL_AIRPORT =
  'Athens International Airport "Eleftherios Venizelos"';
export const STREET_NAME_ATHENS_INTERNATIONAL_AIRPORT = 'Αττική Οδός';
export const STREET_NUMBER_ATHENS_INTERNATIONAL_AIRPORT = '';
export const LOCALITY_ATHENS_INTERNATIONAL_AIRPORT = 'Σπάτα';
export const POSTAL_CODE_ATHENS_INTERNATIONAL_AIRPORT = '190 04';
export const REGION_ATHENS_INTERNATIONAL_AIRPORT =
  'Περιφερειακή ενότητα Βορείου Τομέα Αθηνών';
export const DEFAULT_COUNTRY_ATHENS_INTERNATIONAL_AIRPORT = 'Ελλάδα';
export const DEFAULT_LAT_ATHENS_INTERNATIONAL_AIRPORT = 37.9361751;
export const DEFAULT_LNG_ATHENS_INTERNATIONAL_AIRPORT = 23.9479902;

// Kifisia
export const LOCATION_KIFISIA = 'instacar point - Kifisia';
export const STREET_NAME_KIFISIA = 'Παλαιά Τατοΐου';
export const STREET_NUMBER_KIFISIA = '165Γ';
export const LOCALITY_KIFISIA = 'Κηφισιά';
export const POSTAL_CODE_KIFISIA = '145 64';
export const REGION_KIFISIA = 'Περιφερειακή ενότητα Βορείου Τομέα Αθηνών';
export const DEFAULT_COUNTRY_KIFISIA = 'Ελλάδα';
export const DEFAULT_LAT_KIFISIA = 38.1028828;
export const DEFAULT_LNG_KIFISIA = 23.8142386;
